import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Backdrop from '../../../components/ui/modal/backdrop'
import Msg from '../../../components/ui/alert/alert'
import BtnLoader from '../../../components/btn/BtnLoader'
import validator from 'validator'
import {_API, _EMPRESA} from '../../../config/config'
import axios from 'axios'

class Alterarsenha  extends Component{
  state = {
    email: "",
    err: "",
    succsess: false,
    isLoading: false,
    updateLoader: false

  }
  gerarMsg(tipo, msg){
    switch(tipo){
      case "danger":
        return <Msg msg={msg} type="danger"/> 
      case "success":
        return <Msg msg={msg} type="success"/> 
      default :
        return <Msg msg="Preencha o campo abaixo e enviaremos instruções para você redefinir sua senha por e-mail." type="info"/> 
    }
  }
  f_isLoading =(value)=> {
    this.setState({
      isLoading: value
  })   
  }
  enviarEmail = (e) =>{
    this.f_isLoading(true);
    e.preventDefault();
    const email = this.state.email
    if( !validator.isEmail( email ) )
    {
      this.setState({
          err: "E-mail inválido"
      })
      this.f_isLoading(false);
      return
    }

    axios.post(`${_API}/esqueceuSenha`, {
      cad_empresa : _EMPRESA,
      email: email
    })
    .then((result)=>{
      if(result === false){
        this.setState({
          succsess: true
        })
      }
      else{
        console.log(result.data[0])
        const retorno = result.data[0].RETORNO.split("|")
        const logado  = parseInt(retorno[0]);
        // se autenticação falhar
        if(logado === 0){
          this.setState({
            err: retorno[1]
          })
          this.f_isLoading(false)
        }
        else if(logado === 1){
          this.setState({
            succsess: true
          })
          this.f_isLoading(false)
        }
      }
    })
  }
  inputHandler = (e) =>{
    const email = e.target.value;   
    
    this.setState({
      email: email
    })
  }
  setarEmail
  render(){
    let msg = null
    if(this.state.succsess)
    {
      msg = this.gerarMsg("success", "Um e-mail com as instruções foi enviado para o e-mail fornecido.")
    }
    else if(this.state.err){
      msg = this.gerarMsg("danger", this.state.err)
    }
    else {
      msg = this.gerarMsg("info", "Preencha o campo abaixo e enviaremos instruções para você redefinir sua senha por e-mail.")
    }
    return(
    <Backdrop classBd='backdrop-galeria' modal={this.props.show} f_fechar={this.props.f_fechar}>
      <div className="container">
          <div className="modal-senha">
              <div className="col-sm-6">
                  <div className="alterar-senha">
                      <h4 className="text-main text-center">Esqueci Minha senha</h4>
                      <form action="" onSubmit={this.enviarEmail}>
                        <div className="form-group pd-20-top">
                            {msg}                            
                            <input className="form-control input-lg" type="email" placeholder="Digite o seu email" value={this.state.email} onChange={this.inputHandler}/>
                        </div>
                        <BtnLoader f_func={this.enviarEmail} labelName="Confirmar" isLoading={this.state.isLoading}/>

                      </form>
                  </div>
              </div>
          </div>
      </div>
    </Backdrop>
    )
  }
}

Alterarsenha.propTypes = {
  show: PropTypes.bool,
  f_fechar: PropTypes.func.isRequired,
}


export default Alterarsenha