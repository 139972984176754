import React from 'react'
import Backdrop from '../../ui/modal/backdrop'
import Loader from '../../ui/loader/index'
import Alert from '../../ui/alert/alert'
import './modal.css'
import PropsTypes from 'prop-types'
import BtnLoader from '../../btn/BtnLoader'
import Msg from './msg'

import {_API, _EMPRESA} from '../../../config/config'
import axios from 'axios'

const cores = {
    metaGestao: "#b00069",
    metaRiqueza: "#fdb812",
    academia: "#2b385c",
    default: "#0baf4c",
}
const coresMetanoia = Object.keys(cores);

const inicio = {
    carregando: false,
    tipo: "T",
    conteudo: "",
    corDeFundo: cores.default,
    caracteres: 0,
    img: null,
    imgPreview: null,
    videoPreview: null,
    video: null,
    sucesso: false,
    btn: false,
    erro: [],
    desc: ""
}

class ModalMetagram  extends React.Component {
    state = {
        carregando: false,
        tipo: "T",
        conteudo: "",
        corDeFundo: cores.default,
        caracteres: 0,
        img: null,
        imgPreview: null,
        videoPreview: null,
        video: null,
        sucesso: false,
        btn: false,
        erro: [],
        desc: ""
    }

    limparDados = (e)=>{
        e.preventDefault()
        let newState = {...inicio}
        this.setState({
            ...newState
        })
        this.props.f_fechar()
     }
    f_carregando(){
        this.setState({
            carregando: !this.state.carregando
        })
    }
    mudarCor(e, escolhida){
        e.preventDefault()
        const cor = cores[escolhida]
        this.setState({
            corDeFundo: cor
        })
    }
    mudarTipo(e, tipo){
        e.preventDefault()
        this.setState({
            tipo: tipo,
        })
    }
    inputDesc =(e)=>{

        e.preventDefault()
        this.setState({
            desc: e.target.value,
        })
         
    }

    apagarVideo = () =>{
        this.setState({
            videoPreview: "",
        })
    }

    limparImagemPreview =()=>{
        this.setState({
            img: null
        })
    }
    mudarVideo = (e) =>{
        e.preventDefault();
        const fileItem = e.target.files[0].name;
        console.log(fileItem)
        this.setState({
            video: e.target.files[0],
            videoPreview: fileItem
        })

    }
    mudarImg = (e)=>{
        e.preventDefault()
        const file = new FileReader();
        const arquivo = e.target.files[0]
        console.log(arquivo)
        file.readAsDataURL(arquivo);

        file.onloadend = (event)=>{
            this.setState({
                img:arquivo,
                imgPreview: event.target.result
            })
        }
    }
    caracteres(e){
        e.preventDefault()
        const conteudo = e.target.value 
        const number = parseInt(e.target.value.length)

        if(conteudo.length > 170){
            return
        }
        this.setState({
            caracteres: number
        })

        this.setState({
            conteudo: conteudo
        })
    }

    recarregarDados(){
        this.props.reCarregar()
    }
    errou(err){
        this.setState({
            erro: err,
            carregando: false,
        })
    }

    enviarDados = (e)=>{
        e.preventDefault()
        this.f_carregando();
        let erro = [];
        let that = this;

        const dados = {...this.state}
        // validação
        if(this.state.tipo === "T"){
            if(this.state.conteudo.length < 3){
                erro.push("O campo frase não pode ter menos de três caracteres ou estar vazio")
                this.errou(erro)
                return null
            }
          
            if(erro.length <= 0){
                axios.post(`${_API}/publicacao`, {
                    cad_empresa: _EMPRESA,
                    //cms_id_publicacao: null,
                    cms_id_tipo_publicacao: dados.tipo,
                    cms_id_usuario: localStorage.getItem("_IDUSER"),
                    //cms_titulo: null,
                    cms_conteudo: dados.conteudo,
                    cms_cor: dados.corDeFundo,
                    //caminho: null,
                    //extensao: null

                })
                .then((data)=>{
                    //verificar se retorna com status 200                    
                    that.f_carregando();
                    that.recarregarDados();
                    that.setState({
                        sucesso: true
                    })
    
                })

            }
        }
        else if(this.state.tipo === "I"){
            if(!this.state.img){
                erro.push("Selecione uma imagem")
                this.errou(erro)
                return null
            }

            const fd = new FormData();
            
            fd.append('cad_empresa',_EMPRESA);
            //fd.append('cms_id_publicacao', null);
            fd.append('cms_id_tipo_publicacao', dados.tipo);
            fd.append('cms_id_usuario', localStorage.getItem("_IDUSER"));
            //fd.append('cms_titulo', null);
            fd.append('cms_conteudo',dados.desc);
            fd.append('cms_cor',  dados.corDeFundo);
            fd.append('arquivo', dados.img);
            //fd.append('caminho', null);
            //fd.append('extensao', null);
            if(erro.length <= 0){
                axios.post(`${_API}/publicacao`, fd,  {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    }
                })
                .then((data)=>{
                    //verificar se retorna com status 200                    
                    that.f_carregando();
                    that.recarregarDados();
                    that.setState({
                        sucesso: true
                    })    
                })
            }
        }
        else if(this.state.tipo === "V"){
            if(!this.state.video){
                erro.push("Carregue o seu video antes de enviar")
                this.errou(erro)
                return null
            }

            const fd = new FormData();
            
            fd.append('cad_empresa',_EMPRESA);
            //fd.append('cms_id_publicacao', null);
            fd.append('cms_id_tipo_publicacao', dados.tipo);
            fd.append('cms_id_usuario', localStorage.getItem("_IDUSER"));
            fd.append('cms_titulo', null);
            fd.append('cms_conteudo',dados.desc);
            fd.append('cms_cor',  dados.corDeFundo);
            fd.append('arquivo', dados.video);
            //fd.append('caminho', null);
            //fd.append('extensao', null);
            if(erro.length <= 0){
                axios.post(`${_API}/publicacao`, fd,  {
                    headers: {
                      'Content-Type': 'multipart/form-data'
                    }
                })
                .then((data)=>{
                    //verificar se retorna com status 200                    
                    that.f_carregando();
                    that.recarregarDados();
                    that.setState({
                        sucesso: true
                    })    
                })
            }
        }else{
            
        }
    }

    escolherTipo(tipo){
        switch (tipo) {
            case "T":
                return(
                    <div className="texto">
                        <div className="btncores">
                        <label htmlFor="" style={{display: "block"}}>Cor do depoimento</label>
                            { coresMetanoia.map((cor, i)=>{
                                return  <button className="btn btn-lg" style={{backgroundColor: cores[cor]}} key={i} onClick={(e)=> this.mudarCor(e, cor)} disabled={this.state.carregando}></button>
                            })}
                        </div>
                        <div className="form-group">

                            <label htmlFor="">Frase</label>
                            <textarea className="form-control rounded-0 compartilhar-texto"  
                            style={{backgroundColor: this.state.corDeFundo}} 
                            onChange={(e)=>{this.caracteres(e)}}  
                            placeholder="Digite seu texto" maxLength="170"  
                            disabled={this.state.carregando} 
                            value={this.state.conteudo}
                            >                            
                            </textarea>

                            <small>{this.state.caracteres} de 170 caracteres</small>
                        </div>
                    </div>
                )
            case "I": 
                    return(
                        <div className="input-group image-preview center-block">
                            <input type="text" className="form-control image-preview-filename hidden" disabled="disabled" /> 
                            <span className="input-group-btn center-block">
                            {/* image-preview-clear button */}
                            <button type="button" className="btn btn-default image-preview-clear" style={{display: this.state.img ? "block": "none"}} onClick={this.limparImagemPreview}>
                                <span className="glyphicon glyphicon-remove" /> Remover
                                <img src={this.state.imgPreview} alt=""/>
                            </button>
                            {/* image-preview-input */}
                            <div className="btn btn-default image-preview-input mg-40-top  mg-40-bottom" style={{display: !this.state.img ? "block": "none"}}>
                                <span className="glyphicon glyphicon-folder-open" />
                                <span className="image-preview-input-title"> Carregar imagem</span>
                                <input type="file" accept="image/png, image/jpeg, image/gif" name="input-file-preview" onChange={(e)=>this.mudarImg(e)} disabled={this.state.carregando} /> {/* rename it */}
                            </div>
                            </span>
                            <div className="form-group mg-40-top mg-40-bottom clearfix">
                                <label htmlFor="">Descrição:</label>
                                <textarea className="form-control rounded-0" value={this.state.desc} onChange={this.inputDesc}  disabled={this.state.carregando}></textarea>
                            </div>
                        </div>
                    )
            case "V": 
                return(
                    <div className="input-group image-preview video center-block">
                        <input type="text" className="form-control image-preview-filename hidden" disabled="disabled" /> 
                        <span className="input-group-btn center-block">
                        {/* image-preview-clear button */}
                        <button type="button" className="btn btn-default image-preview-clear" style={{display: this.state.video ? "block": "none"}} >
                            <span className="glyphicon glyphicon-remove" onClick={this.apagarVideo}/> Remover
                            <p>{this.state.videoPreview}</p>
                        </button>
                        {/* image-preview-input */}
                        <div className="btn btn-default image-preview-input mg-40-top  mg-40-bottom" style={{display: !this.state.video ? "block": "none"}}>
                            <span className="glyphicon glyphicon-folder-open" />
                            <span className="image-preview-input-title"> Carregar Video</span>
                            <input type="file" accept="video/*"  onChange={(e)=>this.mudarVideo(e)}/> {/* rename it */}
                        </div>
                        </span>
                        <div className="form-group mg-40-top mg-40-bottom clearfix">
                            <label htmlFor="">Descrição:</label>
                            <textarea className="form-control rounded-0" value={this.state.desc} onChange={this.inputDesc}  disabled={this.state.carregando}></textarea>
                        </div>
                    </div>
                )
            default:
                break;
        }
    }
    render(){
        return(
            <Backdrop classBd={`backdrop backdrop-modalMetagram ${this.props.modal ? "aberto" : ""}`} f_fechar={this.props.f_fechar}>        
            {this.props.modalConteudo  ?             
            <div className="modalMetagram">
                <div className="contentMetagram">

                    <form action="" method="POST" encType="multipart/form-data" onSubmit={(e)=> this.enviarDados(e)}>
                        <div className=" conteudo-box-home  conteudo-box-home-position-relative center-block clearfix">


                            {this.state.sucesso ? <Msg tipo="sucesso" f_func={this.limparDados}/> : null}

                    
                            <div className="header-metagram">
                                <button className={`btn btn-lg ${this.state.tipo === 'T' ? 'btn-ativo' : ''}`} onClick={(e)=> this.mudarTipo(e, "T")}  disabled={this.state.carregando}>
                                    <span className="glyphicon glyphicon-align-left" aria-hidden="true"></span> Texto
                                </button>
                                <button className={`btn btn-lg ${this.state.tipo === 'I' ? 'btn-ativo' : ''}`} onClick={(e)=> this.mudarTipo(e,"I")}  disabled={this.state.carregando}>
                                    <span className="glyphicon glyphicon-camera" aria-hidden="true"></span> Imagem
                                </button>
                                <button className={`btn btn-lg ${this.state.tipo === 'V' ? 'btn-ativo' : ''}`} onClick={(e)=> this.mudarTipo(e, "V")}  disabled={this.state.carregando}>
                                    <span className="glyphicon glyphicon-facetime-video" aria-hidden="true"></span> Video
                                </button>
                            </div>
                            <div className="body-metagram  center-block clearfix">
                                {this.state.erro.length > 0 ? <Alert msg={this.state.erro[0]} /> : null}
                                {this.escolherTipo(this.state.tipo)}

                                <div className="form-group mg-40-top">
                                    <BtnLoader labelName="Compartilhar" isLoading={this.state.carregando} onClick={(e)=> this.enviarDados(e)}  enabledButton={this.state.btn} />
                                </div>
                            </div>
        
                        </div>
                    </form>
                </div>
            </div>
            
            : <Loader />}
        
            </Backdrop>
            ) 
    }
}

ModalMetagram.propTypes = {
    modal: PropsTypes.bool.isRequired
}

export default ModalMetagram