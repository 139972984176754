import React, {Component} from 'react'
import Layout from  '../../components/layout/indexObra'
import Topico from '../../components/forum/topico'
import Page from '../../components/page/Page'
import { connect } from 'react-redux'
import Loader from '../../components/ui/loaderView'
import { listarDadosObra } from '../../store/actions/action.obra'
import { pegarAgenda, apagarAgenda } from '../../store/actions/action.apoio'
import CriarTopico from '../../components/forum/parts/criarTopico'


class Forum extends Component{
    state = {
        loading: true,
        showConteudo: false,
    }

    async componentDidMount(){
        await this.props.dadosObra()      
        this.change()
    }
    change =()=>{
        this.setState({
            loading: false
        })
    }
    controlarModal = (agrupador, id) => {
        this.setState({
            showConteudo: !this.state.showConteudo
        })
        this.props.agenda(agrupador, id);

        if(!this.state.showConteudo)
            this.props.apagar()
    }


    render(){
        return(
            
            <Layout {...this.props}>

                {this.state.loading ? <Loader /> :

                <Page titulo="Forum">
                    <CriarTopico/>
                    <div className="container">
                        <div className="row">
                            <Topico />
                            <Topico />
                            <Topico />
                            <Topico />
                            <Topico />
                        </div>
                    </div>
                </Page>          
                }

            </Layout>
        )
    }
}

const mapStateToProps = (state) => {
    console.table(state)
    return {
        dadosObra: state.obra.dados[0],
        modalConteudo: state.apoio.modal
        
    }
}

const mapActionsToProps = (dispatch) =>{
    return{
        dadosObra: () => dispatch(listarDadosObra()),
        agenda: (agrupador, id) => dispatch(pegarAgenda(agrupador, id)),
        apagar: ()=> dispatch(apagarAgenda())
    }
}

export default connect(mapStateToProps,mapActionsToProps)(Forum)

