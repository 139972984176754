import React from 'react'
import PropTypes from 'prop-types'
import Backdrop from '../ui/modal/backdrop'
import Content from './content'
import Loader from '../ui/loader/index'
import './basilar.css'

const ModalBasilar = (props) =>{
  let mostrar = true;
  let resultado = props.resultado[0]
  if(!props.resultado)
  {
    mostrar = false
  }
  else{
    if(!resultado)
      mostrar = false
  }
  return(    
    <Backdrop classBd='backdrop-galeria boxDevir' modal={props.show} f_fechar={props.f_fechar} cor="white">
        {mostrar ?            
            <Content resultado={resultado} />    
          : 
          <Loader cor={true}/> }        
    </Backdrop>
  )
}

ModalBasilar.propTypes = {
  show: PropTypes.bool.isRequired,
  f_fechar: PropTypes.func.isRequired,
}


export default ModalBasilar