const Webnario = (state = {
    webnario: null
}, action) => {
    switch (action.type) {
        case "WEBNARIO":
            return { ...state, webnar: action.payload }
        case "ZOOM":
            return { ...state, zoom: action.payload }
        default:
            return state
    }
}

export default Webnario