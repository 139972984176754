import React, { Component } from 'react'
import Layout from '../../components/layout/indexObra'
import ContainerWithRow from '../../components/ui/bootstrap/ContainerWithRow'
import Loader from '../../components/ui/loaderView'
import ModalBasilar from '../../components/basilar/ModalBasilar'
import Conteudo from '../../components/conteudo/Conteudo'
import BoxTravessia from '../../components/boxes/obra/boxMPTravessia'
import Page from '../../components/page/Page'
import './mp.css'

// ACTIONS: Atualizar o state da aplicação
import { listarDadosObra, buscarGraficoLume, listarConteudos, leituraConteudo, limparConteudo, imagemMPTravessia } from '../../store/actions/action.obra'
import { connect } from 'react-redux'
class indexObra extends Component {
    state = {
        loading: true,
        show: false,
        showConteudo: false,
        showMobMenu: false
    }
    async componentDidMount() {
        await this.props.pegarDadosObras(" ")
        this.props.pegarGraficoLume()
        this.change()
        this.props.pegarImagem()
        this.props.pegarConteudos()      
    }
    f_modal = (valor) => {
        this.setState({
            show: valor
        })
    }
    change =()=>{
        this.setState({
            loading: false
        })
    }
    f_modal_conteudo = (valor) => {
        if(valor === false){
            this.props.limparConteudoPagina()
        }
        this.setState({
            showConteudo: valor
        })
    }
    f_carregarConteudo(id){
        this.f_modal_conteudo(true)
        this.props.pegarConteudoUnico(id);        
    }
    render() { 
        return (            
            <Layout {...this.props}>
                <Page titulo="Ponto de Vista" className="mapaTravessia ">
                    {this.state.loading ?                 
                        <Loader /> : 
                            <ContainerWithRow fluid={false} classecontainer="pd-80-top boxDevir mp-travessiaContainer">
                                {/** <Slider media={this.props.imagem}/> */}
                                <BoxTravessia carregarConteudo={this.f_carregarConteudo.bind(this)}  
                                                MpTravessiaConteudos={this.props.conteudosObra} 
                                                f_abrir={() => { this.f_modal(true) }} 
                                                abrirModal={()=>{this.f_modal_conteudo()}}
                                                dadosObra={this.props.dadosObra}
                                                />
                            </ContainerWithRow>   
                    }
                </Page>
                {/* Modal do Basilar */}
                <ModalBasilar 
                    show={this.state.show} 
                    f_fechar={() => { this.f_modal(false) }} 
                    resultado={this.props.graficoLume}
                />
                <Conteudo show={this.state.showConteudo} 
                    f_fechar_conteudo={() => { this.f_modal_conteudo(false) }} 
                    conteudo={this.props.pageConteudo}/>
            </Layout>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        conteudosObra: state.obra.conteudos,
        dadosObra: state.obra.dados[0],
        graficoLume: state.obra.graficoLume,
        pageConteudo: state.obra.conteudoPagina,
        imagem: state.obra.imagemTravessia
         
    }
}



const mapActionsToDispatch = (dispatch) => {
    return {
        pegarDadosObras: (texto) => dispatch(listarDadosObra(texto)),
        pegarGraficoLume: () => dispatch(buscarGraficoLume()),
        pegarConteudos: ()=> dispatch(listarConteudos()),
        pegarConteudoUnico: (id_conteudo) => dispatch(leituraConteudo(id_conteudo)),
        limparConteudoPagina: ()=> dispatch(limparConteudo()),
        pegarImagem: ()=> dispatch(imagemMPTravessia())
    }
}

export default connect(mapStateToProps, mapActionsToDispatch)(indexObra)