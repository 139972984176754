import React from 'react'

const ContentDown = (props)=>{
    return(
        <div className="box">
        <div className="col-sm-12 col-xs-12 basiliar-wraper">
        <div className="conteudo-box-home">        
            <h5 className="text-left">Material de apoio:</h5>
            {props.materiais.map( ( item, i )=>{
                return(
                    <div key={i} style={{marginBottom:'8px'}}>
                        <a href={item.arquivoLink} target="_blank" rel="noopener noreferrer">{item.CMS_NOME_TIPO_ARQUIVO} 
                        <span className="glyphicon glyphicon-save" aria-hidden="true"></span>
                        </a>
                    </div>
                )
            })}
        </div>
      </div>
    </div>        
    )
}

export default ContentDown