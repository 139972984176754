import React from 'react'

const HeaderMetagram = (props)=>{
    return(
        <div className="header-box">
            {props.idUsuario == localStorage.getItem("_IDUSER") ? <button className="deletar-post" onClick={props.f_deletar}> x </button> : null}
            <div className="info-post">
                <img src={props.foto ? props.foto : '/images/usuario.png' } alt="Usuário"/>
                <div>
                    <h6 className="titulo-metagram bold">{props.nome}</h6>
                    <p className="bold">{props.obra}</p>
                </div>
            </div>
        </div>
    );
}

export default HeaderMetagram