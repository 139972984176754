import React from 'react'
import ItemBox from './listBox/listBoxItem'
import PropTypes from 'prop-types'
import './boxes.css'

const Box = (props) =>{
    let styles = {};
    if(props.height)
        styles.height = `${props.height}px`
    if(props.width)
        styles.width = `${props.width}%`        
    switch(props.tipo){
        case 'comLimite':
            return(
                <div className="box-home" style={styles}>
                    <div className="box-content" style={styles}>
                        {props.children}
                    </div>
                </div>
            )
        case 'lista':
            return(
                <div className="box-home">
                    <div className="box-content">
                        <ItemBox />
                        <ItemBox />
                        <ItemBox />
                    </div>
                    <div className="box-footer">
                        {props.footer}
                    </div>
                </div>
            )
        default:
            return(
                <div className="box-home" style={styles}>
                    {props.children}
                </div>               
            )
    }
}

Box.propTypes = {
    height: PropTypes.number,
    width: PropTypes.number
}

export default Box

