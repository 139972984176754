import React from 'react'
const Footer = (props)=>{
    console.log(props)
    const logos = props.dadosObra.FOTO
    return(
        <footer className="rodape"> 
          {logos ? <img className="img-center center-block" src={logos} style={{maxHeight:40, marginBottom: 30}}/> : null}
          <div className="container">
            <div className="bg-metanoia">
              <div className="row text-center logos" style={{display:'flex', alignItems:'center'}}>
                <div className="col-sm-12 text-center"><a href="http://happybiz.com.br/" rel="noopener noreferrer"  target="_blank" ><img className="img-center center-block" src="/images/logo-biz-cinza.png" alt="Simplesnologia" height="15px" /></a></div>
              </div>
            </div>
          </div>
        </footer>
    )
}

export default Footer