import React from 'react'
import PropTypes from 'prop-types'
import './webnario.css'
import svg from './icone.svg'
import svgZoom from './icone-zoom.svg'

//redux
import {connect} from 'react-redux'

function webnarioIcone(tipo, props){

    const valores = props.webnar || props.zoom

    switch (tipo) {
        case 'zoom':
            return(
            <div className="webNarioContent">
                <p className="texto">{valores.CMS_TITULO_ANEXO ? valores.CMS_TITULO_ANEXO[0] : "---" }</p>
                <a href={valores.CMS_LINK} target="_blank"rel="noopener noreferrer" >
                    <div className="webnarioIcon">
                        <img src={svgZoom} alt="Clique para ir para o webnario"/>
                    </div>
                </a>
            </div>
            )

        case 'webnario':
            return(
            <div className="webNarioContent">
                <p className="texto">{valores.CMS_TITULO_ANEXO ? valores.CMS_TITULO_ANEXO[0] : "---" }</p>
                <a href={valores.CMS_LINK} target="_blank" rel="noopener noreferrer">
                    <div className="webnarioIcon">
                        <img src={svg} alt="Clique para ir para o webnario"/>
                    </div>
                </a>
            </div>   
            )
        
    
        default:
            break;
    }
}

const WebnarioIcon = (props) =>{
    if(!props.webnar && !props.zoom)
        return null

    let renderered = null;
    if(props.webnar)
        renderered = webnarioIcone("webnario", props)
    if(props.zoom)
        renderered = webnarioIcone("zoom", props)
    console.log(renderered)
    return(
        <React.Fragment>
            {
                renderered
            }
        </React.Fragment>
    )
}

WebnarioIcon.propTypes = {
    link: PropTypes.string,
    texto: PropTypes.string,
    zoom: PropTypes.bool
}

const mapStateToProps = (state)=>{

    return{
        webnar: state.webnario.webnar,
        zoom: state.webnario.zoom
    }
}

export default connect(mapStateToProps)(WebnarioIcon)