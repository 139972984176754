import axios from 'axios'
import { _API, _EMPRESA} from '../../config/config'

export async function trocarEmpresa() {
    let url = `${_API}/listarObrasMaster/?cad_empresa=${_EMPRESA}&cms_id_usuario=${localStorage.getItem('_IDUSER')}`

    let request = await axios.get(url)
        .then(response => {
            return response.data
        })
    return {
        type: "CARREGAR_EMPRESA",
        payload: request
    }
}


export async function gravarEmpresa(idEmpresa) {
    let url = `${_API}/gravarMaster`

    let request = await axios.post(url,{
        cad_empresa : _EMPRESA,
        crm_codigo: idEmpresa,
        cms_id_usuario: localStorage.getItem('_IDUSER'),
    })
        .then(response => {
            return response.data
        })
    return {
        type: "RELOAD_APP",
        payload: request
    }
}

