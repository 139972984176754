import React, {Component} from 'react'
import Alert from '../../components/ui/alert/alert'
import {_API} from '../../config/config'
import axios from 'axios'
import Transition from 'react-transition-group/CSSTransition'
import EmailInput from './parts/EmailInput'
import PassInput from './parts/PassInput'
import BtnLoader from '../../components/btn/BtnLoader'
import Senha from './parts/AlterarSenha'
import AtualizarSenha from './parts/AtualizarSenha'
import './login.css'
class Login extends Component{
    state = {
        email: "",
        pass: "",
        isLoading: false,
        enabledButton: true,
        errMsg: "",
        fadeIn: false,
        mostrarAlterarSenha: false,
        mostrarUpdateSenha: false
    }
    handleInputEmail = (e)=>{
        this.setState({
            email: e.target.value
        })
        this.enabledButton()
    }
    enabledButton = ()=>{
        if(this.state.pass && this.state.email){
            this.setState({
                enabledButton: false
            })     
        }else{
            this.setState({
                enabledButton: true
            })              
        }
    }
    handleInputPass = (e)=>{
        this.setState({
            pass: e.target.value
        })
        this.enabledButton()
    }
    changeIsLoading = ()=>{
        this.setState({
            isLoading: !this.state.isLoading
        })
    }
    setError = (msg) =>{
        this.setState({err: msg})
    }
    submitForm = (e) =>{
        this.changeIsLoading();
        e.preventDefault();
        e.stopPropagation();
        this.enabledButton();
        // não permitir acesso casos os campos password e email não estejam setados  // 
        axios.post(`${_API}/validarLogin`, {empresa:"001",cms_login: this.state.email, cms_senha: this.state.pass})
            .then((res)=>{
                let resultado   = res.data[0].RETORNO.split("|");
                let logado      = resultado[0];
                let id          = resultado[1];
                if(logado === "1"){
                    // se existir direcinar para a página de obras
                    localStorage.setItem('_IDUSER', id)
                    this.props.history.push("/obra");

                }
                else if(logado === "2"){
                    //tratamento para alterar a senha do usuario
                    localStorage.setItem('_IDUSER', id)
                    
                    this.setState({
                        mostrarUpdateSenha: true
                    })
                    this.changeIsLoading();
                    //this.props.history.push("/obra");
                }
                else{
                    // caso contrario setar uma mensagem de erro
                    this.setState({
                        errMsg: resultado[1]
                    })
                    this.changeIsLoading();
                }
            })
            .catch((err)=>{
                this.setState({
                    errMsg: "Usuário ou senha inválido"
                });
                this.changeIsLoading();
            })

    }
    fecharAlterarSenha =( e, valor )=>{
        console.log("teste")
        e.preventDefault();
        e.stopPropagation();
        this.setState({
            mostrarAlterarSenha: valor
        })
    }
    fecharUpdateSenha =( e, valor )=>{
        e.preventDefault();
        this.setState({
            mostrarUpdateSenha: valor
        })
    }
    componentDidMount(){
        // se existir o _idUser redirecionar o usuario para a home da Obra
        let user = window.localStorage.getItem('_IDUSER');
        if(user){
            this.props.history.push("/obra");
        }

        this.setState({            
            fadeIn: true        
        })
    }
    render(){
        const err = this.state.errMsg.length > 0 ? true : false
        return(
            <Transition in={this.state.fadeIn} timeout={1000}>
                    {state =>                            
                            <div className={`tudo loginDevir login-${state}`}>
                            <Senha show={this.state.mostrarAlterarSenha} f_fechar={this.fecharAlterarSenha}/>                                        
                            <AtualizarSenha show={this.state.mostrarUpdateSenha} f_fechar={this.fecharUpdateSenha} {...this.props} reload={true}/>
                            <div className="login-wrraper">
                                <div className="login">
                                    <img className="img-responsive img-center center-block" src="/images/logos-coloridos/incandessencia.png" alt="Conxole Admin" style={{maxWidth:"120px"}}/>
                                    <div className="form">
                                        <form className="validate" id="formLogin" acceptCharset="UTF-8" >
                                            <div className="output" style={{width: "100%"}}>
                                                {err ? <Alert msg={this.state.errMsg} /> : null}
                                            </div>
                                            <EmailInput handleInputEmail={this.handleInputEmail}/>
                                            <PassInput handleInputPass={this.handleInputPass} enabledButton={this.enabledButton}/>
                                            <BtnLoader isLoading={this.state.isLoading}  f_func={(e)=> this.submitForm(e)} enabledButton={this.state.enabledButton} labelName="Acessar" />
                                            <div className="checkbox text-left">
                                                <label>
                                                    <input type="checkbox" id="login_cbxContinuarLogado" style={{height:"10px"}}/>&nbsp;Continuar conectado?
                                                </label>
                                            </div>
                                            <hr/>
                                            <div className="text-center">
                                                <button className="text-atual text-bold text-center btn-none" onClick={(e)=>{this.fecharAlterarSenha(e, true)}}>Esqueci minha senha</button>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }
            </Transition>

        )
    }
}

export default Login