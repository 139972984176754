import React, { Component, Fragment } from 'react'

class Responder extends Component{

    render(props){
        return(
            <Fragment>
                <div className="digitarResposta" style={{display:this.props.mostrar ? "block" : "none"}}>
                    <div className="form-group text-left">
                        <label className="text-left" htmlFor="resposta">Responder</label>
                    </div>
                    <div className="text-right">
                        <button className="btn-enviar btn" onClick={this.props.comentario}>Enviar</button>
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default Responder