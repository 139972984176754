import React, {Fragment} from 'react'
import './metagram.css'
import  ItemMetagram from './item/MetagramFeedItem'
import Page from '../page/Page'
import Loader from '../ui/loader/index'

import {
    CSSTransition,
    TransitionGroup,
  } from 'react-transition-group';

const Metagram = (props)=>{
    return(
        <Fragment>
            <div className="container">
                <div className="row">
                    <div className="col-sm-12">
                        <div className="logoMatagram">
                            <Page titulo="Compartilhamento">
                                <div className="radar"></div>
                                <button className="btn-mais" onClick={props.f_abrir}></button>
                            </Page>
                        </div>
                    </div>
                </div>
                <div className="row">
                        {props.feed === null ? 
                        <div style={{padding: "40px"}}>
                            <Loader cor={true}/> 
                        </div>
                        : ""}
                        {Array.isArray(props.feed) ? 
                            <TransitionGroup>
                                {props.feed.map((item, i)=> {
                                    console.log(item)
                                return (
                                    <CSSTransition
                                        key={i}
                                        unmountOnExit
                                        timeout={500}
                                        classNames="metanoia-animation">
                                        <ItemMetagram 
                                                key={i + Math.random()}
                                                nome={item.apelido} 
                                                obra={item.nomeObra} 
                                                tipo={item.tipoPublicacao.toLowerCase()} 
                                                source={item.tipoPublicacao.toLowerCase() === "texto" ? item.conteudo : item.caminhoArquivoPublicacao}
                                                fotoPerfil={item.fotoPerfil}
                                                desc={ item.conteudo }
                                                cor={item.cor}
                                                idPublicacao={item.idPublicacao}
                                                deletarFedd={props.deletarFedd}
                                                idUsuario={item.idUsuario}
                                                comentarios={props.comentarios} 
                                                click={props.click}
                                                formatoArquivoPublicacao={props.formatoArquivoPublicacao}
                                                />
                                    </CSSTransition>                    
                                    )
                                })}
                                        
                            </TransitionGroup>
                            
                            : 

                            null
                            }


                </div>
            </div>
        </Fragment>
    );
}
export default Metagram
