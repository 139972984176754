import React, {Component} from 'react'
import PropTypes          from 'prop-types'
import Backdrop           from '../../../components/ui/modal/backdrop'
import Msg                from '../../../components/ui/alert/alert'
import BtnLoader          from '../../../components/btn/BtnLoader'
import {_API, _EMPRESA}   from '../../../config/config'
import './termoAceite.css'
import axios from 'axios'

const termoDeAceito = ()=>{
  return(
    <div className="form-control input-lg termo-aceite">
    <p className="text-left">Na qualidade de cliente do Portal da Cultura ao qual participo nesta plataforma, antes de baixar e ter acesso ao seu conteúdo, faço as seguintes declarações:</p>
    <ol>
      <li>
      O material aqui disponibilizado faz parte do Compêndio Digital do Portal da Cultura contratado com a minha participação nesse programa e cujo pagamento já foi ou está sendo efetivado por mim ou por minha cooperativa.     </li>
      <li>
       O material baixado ou de acesso remoto não pode ser alterado, adulterado ou modificado em qualquer situação, seja em sua forma ou conteúdo, assim, me comprometo a manter sua total integridade.      </li>
      <li>
      Tenho ciência que o material baixado ou de acesso remoto possui cunho educacional e será utilizado exclusivamente para o meu crescimento profissional, de minha cooperativa e da minha equipe.   </li>
      <li>
      material baixado ou de acesso remoto só poderá ser reproduzido nos termos de autorização da empresa cedente - a Metanoia Propósito nos Negócios - para os mesmos fins educacionais vinculados a esta plataforma de aprendizado.    </li>
      <li>
      O material baixado ou de acesso remoto não poderá ser utilizado em outra cooperativa que não tenha comprado este mesmo processo de educação que me dá acesso ao referido Compêndio Digital nos termos desta plataforma de aprendizado.    </li>
      <li>Fica acordado que o limite de tempo para acesso ou utilização da versão disponibilizada do material, é o mesmo período em que ocorre o processo de educação que estou participando. Neste caso, aceito desde já a restrição de acesso, desde que previamente informado pela a Metanoia Propósito nos Negócios.</li>
    </ol>
    <p>
    E assim, declaro estar ciente dos termos acima, de acordo com as considerações e declaro, ainda, ser guardião do conteúdo e da forma ao qual faço acesso, e desta forma assino digitalmente através de meu aceite.     </p>
</div>
  )
}

class termoDeAceite  extends Component{
  state = {
    err: "",
    succsess: false,
    isLoading: false,
    forcarUpdate: false
  }
  gerarMsg(tipo, msg){
    switch(tipo){
      case "danger":
        return <Msg msg={msg} type="danger"/> 
      case "success":
        return <Msg msg={msg} type="success"/> 
      default :
        return <Msg msg="Leia com ATENÇÃO todo o campo abaixo e se concordar clique no botão ACEITO para acessar o Portal da Cultura" type="danger"/> 
    }
  }
  f_isLoading =(value)=> {
    this.setState({
      isLoading: value
  })   
  }
  confirmarAlert = (e) =>{
    this.f_isLoading(true);
    e.preventDefault();

    axios.post(`${_API}/termoAceite`, {
      cad_empresa : _EMPRESA,
      cms_id_usuario: localStorage.getItem('_IDUSER')
    })
    .then((res)=>{
      const result = res.data[0].RESULTADO.split("|")[0]
      if(result === "0")
      {
        this.setState({
          err: "Erro ao tentar gravar, por favor entre em contato conosco."
        })
      }
      else{
        var that =  this
        that.setState({
          succsess: true,
        })
        const func = function(){
          that.setState({
            forcarUpdate: true
          })
        }
        setTimeout(func, 3000)

      }
    })
  }

  render(){
    let msg = null
    if(this.state.succsess)
    {
      msg = this.gerarMsg("success", "Você aceitou nosso termo de uso.")
    }
    else if(this.state.err){
      msg = this.gerarMsg("danger", this.state.err)
    }
    else {
      msg = this.gerarMsg("info", "Preencha o campo abaixo e enviaremos instruções para você redefinir sua senha por e-mail.")
    }
    return(
    <Backdrop classBd='backdrop-galeria' modal={this.state.forcarUpdate ? false : this.props.show} f_fechar={this.props.f_fechar} esconderFechar={true}>
      <div className="container">
          <div className="modal-senha">
              <div className="col-sm-6">
                  <div className="alterar-senha">
                      <h4 className="text-main text-center bold">TERMO DE USO DO MATERIAL DA PLATAFORMA DE APRENDIZADO</h4>
                      <form action="" onSubmit={this.confirmarAlert}>
                        <div className="form-group pd-20-top">
                            {msg}                            
                            {termoDeAceito()}
                        </div>
                        <BtnLoader f_func={this.confirmarAlert} labelName="ACEITO" isLoading={this.state.isLoading} enabledButton={this.state.forcarUpdate}/>
                        </form>
                    </div>
                </div>
            </div>
        </div>
      </Backdrop>
    )
  }
}

termoDeAceite.propTypes = {
  show: PropTypes.bool,
  f_fechar: PropTypes.func.isRequired,
}


export default termoDeAceite