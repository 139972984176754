import React from 'react'
import './item.css'
const ItemResultado = (props) =>{
    let cor = '#d1c71e';
    if( props.porc >= 61 && props.porc <= 80 ){
        cor = '#fda51a';
    }
    else if( props.porc >= 81){
        cor = '#0054a8';
    }
    return(
        <div className={`item-box item-basilar ${props.className}`}>
            <p className="text-left">{props.nome}</p>
            <div className="divBG">
                <div className="divCor" style={{width:`${props.porc}%`, backgroundColor: cor }}>
                </div>
            </div>
            <p>{`${props.porc}%`}</p>
        </div>
  
    )
}






export default ItemResultado