import React from 'react';
import loader from '../../components/ui/loader/loader.svg'
import PropTypes from 'prop-types';
import './btnLoader.css'
/**
 * Botão com Feedback de carregamento de conteudo (Loader)
 */
const BtnLoader = (props) =>(
    <div className="wrapper-loader">
        <button className={`btn btn-lg btn-main ${props.notblock ? '': 'btn-block'}`} type="submit" onClick={props.f_func} disabled={props.enabledButton}>
            {props.isLoading ? <img src={loader}  className="loader-login" alt="conteúdo sendo carregado"/> : props.labelName }
        </button>
    </div>
)

BtnLoader.propTypes = {
    /** Função para o evento click */
    f_func      :   PropTypes.func,
    labelName   :   PropTypes.string.isRequired,
    isLoading   :   PropTypes.bool.isRequired,
    notblock    :   PropTypes.bool

}
export default BtnLoader