import React from 'react'
import imgLoader from './loader.svg'
import imgLoaderAzul from './loader_azul.svg'
import './loader.css'

const Loader = (props)=>{
    return(
        <div className="loaderWrapper">
            <img src={props.cor ? imgLoaderAzul : imgLoader} alt="Loader" className="loaderContent" />
        </div>
    )
}

export default Loader