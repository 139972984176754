import React from 'react'
import PropTypes from 'prop-types'

const ModalGaleria = (props) =>{
  if(!props.foto)
    return null
  return(
    <div className={`backdrop center-flex backdrop-galeria ${props.show ? "aberto" : ""}`}>
      <div className="galeria-modal-container">

        <div className="fechar" onClick={props.f_fechar}>
          <span>x</span>
        </div>
        <img className="img-responsive center-block" src={props.foto.thumbnail} alt="" />
      </div>
      <div className="bnt-next">
        <img className="anterior setas" src="/images/proximo.png" alt="Imagem anterior" onClick={props.f_Voltar}/>
        <img className="proximo setas" src="/images/proximo.png" alt="Proxima Imagem" onClick={props.f_Proximo}/>
      </div>
    </div>       
  )
}

ModalGaleria.propTypes = {
  show: PropTypes.bool.isRequired,
  f_Modal: PropTypes.func.isRequired,
}


export default ModalGaleria