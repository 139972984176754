import React from 'react'

//<span> {props.data}</span>
const ModalItem = (props) =>{
    return(
    <>
        <div className={`agenda ${props.esconder ? ' ' : 'agenda-ativo'}`}>
            <span>{props.nome}</span>
            
        </div>
        <div className="desc" style={{display: props.esconder ? "none" : "block"}}>
            <small><i>{props.tipo}</i></small>
            <p>{props.desc}</p>
        </div>
    </>
    )
}

export default ModalItem