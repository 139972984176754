import React, {useState, useEffect} from 'react'
import BtnLoader                    from '../btn/BtnLoader'
import {_API, _EMPRESA}             from '../../config/config'
import axios                        from 'axios'
import {connect}                    from 'react-redux'
import {leituraConteudo}            from '../../store/actions/action.obra'
import Alerta                       from '../../components/ui/alert/alert'
import {useTransition, animated} from 'react-spring'


const Responder = (props) =>{
    const[loading, setarLoader] = useState(false)
    const[msg, setarMsg]        = useState("")
    const[enviar, setarEnvio]   = useState(false)
    const[alerta, setarAlerta]  = useState({tem: false, msg:"", tipo: ""})
    async function enviarDados (){
      let header = {
        cad_empresa       : _EMPRESA,
        cms_id_conteudo   : props.conteudo.idConteudo,
        cms_id_comentario : null,
        cms_id_usuario    : localStorage.getItem('_IDUSER'),
        cms_mensagem      : msg,
        cms_id_comentario_origem: null
      }

      if(props.nivel === 2){
        header = {
          ...header,
          cms_id_comentario_origem: props.idComentario
        }
      }

      if(msg.length <= 3){
        setarAlerta({tem: true, msg: "Por favor preencha o campa abaixo.", tipo: "danger"})
        setarLoader(false)
        return
      }
                    
      await axios.post(`${_API}/conteudoComentario`, header)
        .then((res)=>{
          const result = res.data[0].RESULTADO.split("|")[0]
          props.pegarConteudoUnico(props.conteudo.idConteudo)
          setarLoader(false)
          setarMsg("")
          setarAlerta({tem: true, msg: "Mensagem enviada", tipo: "success"})
        })
        .catch((err)=>{
          setarAlerta({tem: true, msg: "Erro ao enviar sua mensagem tente novamente mais tarde.", tipo: "danger"})
          setarLoader(false)
        })
    }
    

    return(
      <div className="comentario">
        
        {alerta.tem  && <Alerta msg={alerta.msg} alerta={true} type={alerta.tipo} />}

        <form className="form-group" onSubmit={(e)=>{
          e.preventDefault()
          setarEnvio(!enviar)
        }}>

          <label className="text-main">{`${props.nivel === 2 ? '': 'Deixe sua mensagem:'}`}</label>
          <textarea className="form-control" value={msg} onChange={(e)=>{setarMsg(e.target.value)}} rows="3"></textarea>
          <BtnLoader labelName={`${props.nivel === 2 ? 'Responder': 'Enviar'}`} f_func={
            ()=>{
              setarLoader(true)
              enviarDados()       
            }} isLoading={loading} enabledButton={loading}/>
        </form>
      </div>
    )
}

const mapStateToProps = (state) => {
    return {
        conteudo: state.obra.conteudoPagina[0]         
    }
}
const mapActionsToDispatch = (dispatch) => {
    return {
        pegarConteudoUnico: (id_conteudo) => dispatch(leituraConteudo(id_conteudo)),

    }
}
export default connect(mapStateToProps, mapActionsToDispatch)(Responder)