import React, {PureComponent} from 'react'
import Footer                   from './footer'
import Header                   from './HeaderObra'
import AtualizarSenha           from '../../containers/login/parts/AtualizarSenha'
import {connect}                from 'react-redux'
import Icon                     from '../icons/trocarSenha'
import WebnarioIcon             from '../../components/webnario/webnarioIcon'
import {pegarWenario, pegarZoom} from  '../../store/actions/action.webnar'
import TermoDeAceite                from '../../containers/login/parts/termoDeAceite'
import { pegarAlertas }             from '../../store/actions/action.alertas'
import Admin                        from '../admin/admin'
import ModalAlertas                 from '../../components/modalAlertas/modalAlertas'

class Layout extends PureComponent{
    state = {
        showMobMenu: false,
        loading: true,
        senha: false,
        mostrarAceite: true,
    }
    f_logOut = ()=>{
        console.log(window.localStorage)
        window.localStorage.setItem("_IDUSER", "")
        this.props.history.push('/login');
    }
    f_fecharTermo = ()=>{
        this.setState({
            mostrarAceite: !this.state.mostrarAceite
        })
    }
    f_mobMenu = (value)=>{
        this.setState({
            showMobMenu: value
        })
    }
    f_mobSenha = (value) =>{
        this.setState({
            senha: value
        })
    }
    componentDidMount(){
        this.props.webnario()
        this.props.zoom()
        this.props.alertas()
    }

    render(){
        if(!this.props.dadosObra)
            return null
        return(            
            <div className="metaconexaoAPP">
            <div className="tudo">
                <Admin/>
                {/*<ListarAlertas/>*/}
                <ModalAlertas show={true} />
                <TermoDeAceite show={this.props.dadosObra.VERIFICAR_ACEITE} f_fechar={this.f_fecharTermo}/>

                <Header dadosObra={this.props.dadosObra} logOut={this.f_logOut} showMobMenu={this.state.showMobMenu} f_mob={this.f_mobMenu}/>

                {this.props.children}

                <Icon f_func={()=>{this.f_mobSenha(true)}}/>
                <AtualizarSenha show={this.state.senha} f_fechar={()=>{this.f_mobSenha(false)}}/>
            </div>                                 
            <WebnarioIcon texto="Estaremos ao Vivo as 13:30" link="https://youtu.be/28aWHrNYYlQ"/>
            <Footer dadosObra={this.props.dadosObra} />
          </div>
        )
     }
}

const mapStateToProps = (state)=>{
    return{
        dadosObra: state.obra.dados ? state.obra.dados[0] : {}
    }
    
}

const mapActionsToProps = (dispatch)=>{
    return {
        webnario: ()=> dispatch(pegarWenario()),
        zoom: ()=> dispatch(pegarZoom()),
        alertas: ()=> dispatch(pegarAlertas())
    }
}
export default connect(mapStateToProps, mapActionsToProps)(Layout)
