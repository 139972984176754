import React from 'react'
import Texto from './tiposBody/texto'
import Video from './tiposBody/video'
import Imagem from './tiposBody/imagem'

const bodyMetagram = (props)=>{
    switch(props.tipo){
        case "video":
            return(
                <Video source={props.source}/>
            );
        case "imagem":
            return(
                <Imagem source={props.source}/>
            );
        default :
            return(
                <Texto source={props.source}  cor={props.cor}/>
            );
    }
}

export default bodyMetagram