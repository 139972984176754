import React from 'react'

const Msg = (props)=>{
    return(
        <div className="wrraperMsgModal sucesso">
            <div className="centralizar">
                <p>Post criado com sucesso!!</p>
                <button className="btn btn-block btn-lg btn-success" onClick={(e)=>props.f_func(e)}>Sair</button>
            </div>
        </div>
    )
}

export default Msg