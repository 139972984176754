import React, { Component } from 'react'
import ModalGaleria from './galeriaModal/Modal'
import ImagensModal from './itemGaleria'
import PropTypes from 'prop-types'
import './galeria.css'

const galeria = [
    {
        src:       'images/sliders/imagemGrande/Project_20190516_0021.jpg',
        thumbnail: 'images/sliders/imagemGrande/Project_20190516_0021.jpg',
        caption:    "PropTypes.string "         
    },
    {
        src:       'images/sliders/imagemGrande/Project_20190516_0029.jpg',
        thumbnail: 'images/sliders/imagemGrande/Project_20190516_0029.jpg',
        caption:    "PropTypes.string "         
    },
    {
        src:       'images/sliders/imagemGrande/Project_20190516_0030.jpg',
        thumbnail: 'images/sliders/imagemGrande/Project_20190516_0030.jpg',
        caption:    "PropTypes.string "         
    },
    {
        src:       'images/sliders/imagemGrande/Project_20190516_0049.jpg',
        thumbnail: 'images/sliders/imagemGrande/Project_20190516_0049.jpg',
        caption:    "PropTypes.string "         
    },
    {
        src:       'images/sliders/imagemGrande/Project_20190516_0052.jpg',
        thumbnail: 'images/sliders/imagemGrande/Project_20190516_0052.jpg',
        caption:    "PropTypes.string "         
    },
    {
        src:       'images/sliders/imagemGrande/Project_20190516_0055.jpg',
        thumbnail: 'images/sliders/imagemGrande/Project_20190516_0055.jpg',
        caption:    "PropTypes.string "         
    },
    {
        src:       'images/sliders/imagemGrande/Project_20190516_0069.jpg',
        thumbnail: 'images/sliders/imagemGrande/Project_20190516_0069.jpg',
        caption:    "PropTypes.string "         
    },
    {
        src:       'images/sliders/imagemGrande/Project_20190516_0170.jpg',
        thumbnail: 'images/sliders/imagemGrande/Project_20190516_0170.jpg',
        caption:    "PropTypes.string "         
    },
    {
        src:       'images/sliders/imagemGrande/Project_20190516_0171.jpg',
        thumbnail: 'images/sliders/imagemGrande/Project_20190516_0171.jpg',
        caption:    "PropTypes.string "         
    },
    {
        src:       'images/sliders/imagemGrande/Project_20190516_0174.jpg',
        thumbnail: 'images/sliders/imagemGrande/Project_20190516_0174.jpg',
        caption:    "PropTypes.string "         
    },
    {
        src:       'images/sliders/imagemGrande/Project_20190516_0176.jpg',
        thumbnail: 'images/sliders/imagemGrande/Project_20190516_0176.jpg',
        caption:    "PropTypes.string "         
    },
    {
        src:       'images/sliders/imagemGrande/Project_20190516_0183.jpg',
        thumbnail: 'images/sliders/imagemGrande/Project_20190516_0183.jpg',
        caption:    "PropTypes.string "         
    },
    {
        src:       'images/sliders/imagemGrande/Project_20190516_0208.jpg',
        thumbnail: 'images/sliders/imagemGrande/Project_20190516_0208.jpg',
        caption:    "PropTypes.string "         
    },
    {
        src:       'images/sliders/imagemGrande/Project_20190516_0211.jpg',
        thumbnail: 'images/sliders/imagemGrande/Project_20190516_0211.jpg',
        caption:    "PropTypes.string "         
    },

    {
        src:       'images/sliders/imagemGrande/Project_20190516_0212.jpg',
        thumbnail: 'images/sliders/imagemGrande/Project_20190516_0212.jpg',
        caption:    "PropTypes.string "         
    },
    {
        src:       'images/sliders/imagemGrande/Project_20190516_0217.jpg',
        thumbnail: 'images/sliders/imagemGrande/Project_20190516_0217.jpg',
        caption:    "PropTypes.string "         
    },
    {
        src:       'images/sliders/imagemGrande/Project_20190516_0219.jpg',
        thumbnail: 'images/sliders/imagemGrande/Project_20190516_0219.jpg',
        caption:    "PropTypes.string "         
    },
    {
        src:       'images/sliders/imagemGrande/Project_20190516_0238.jpg',
        thumbnail: 'images/sliders/imagemGrande/Project_20190516_0238.jpg',
        caption:    "PropTypes.string "         
    },
    {
        src:       'images/sliders/imagemGrande/Project_20190516_0245.jpg',
        thumbnail: 'images/sliders/imagemGrande/Project_20190516_0245.jpg',
        caption:    "PropTypes.string "         
    },
    {
        src:       'images/sliders/imagemGrande/Project_20190516_0247.jpg',
        thumbnail: 'images/sliders/imagemGrande/Project_20190516_0247.jpg',
        caption:    "PropTypes.string "         
    },
    {
        src:       'images/sliders/imagemGrande/Project_20190516_0266.jpg',
        thumbnail: 'images/sliders/imagemGrande/Project_20190516_0266.jpg',
        caption:    "PropTypes.string "         
    },
    {
        src:       'images/sliders/imagemGrande/Project_20190516_0306.jpg',
        thumbnail: 'images/sliders/imagemGrande/Project_20190516_0306.jpg',
        caption:    "PropTypes.string "         
    },
    {
        src:       'images/sliders/imagemGrande/Project_20190516_0308.jpg',
        thumbnail: 'images/sliders/imagemGrande/Project_20190516_0308.jpg',
        caption:    "PropTypes.string "         
    },
    {
        src:       'images/sliders/imagemGrande/Project_20190516_0315.jpg',
        thumbnail: 'images/sliders/imagemGrande/Project_20190516_0315.jpg',
        caption:    "PropTypes.string "         
    },
    {
        src:       'images/sliders/imagemGrande/Project_20190516_0317.jpg',
        thumbnail: 'images/sliders/imagemGrande/Project_20190516_0317.jpg',
        caption:    "PropTypes.string "         
    },
    {
        src:       'images/sliders/imagemGrande/Project_20190516_0318.jpg',
        thumbnail: 'images/sliders/imagemGrande/Project_20190516_0318.jpg',
        caption:    "PropTypes.string "         
    },
    {
        src:       'images/sliders/imagemGrande/Project_20190516_0327.jpg',
        thumbnail: 'images/sliders/imagemGrande/Project_20190516_0327.jpg',
        caption:    "PropTypes.string "         
    },
    {
        src:       'images/sliders/imagemGrande/Project_20190516_0342.jpg',
        thumbnail: 'images/sliders/imagemGrande/Project_20190516_0342.jpg',
        caption:    "PropTypes.string "         
    },
    {
        src:       'images/sliders/imagemGrande/Project_20190516_0363.jpg',
        thumbnail: 'images/sliders/imagemGrande/Project_20190516_0363.jpg',
        caption:    "PropTypes.string "         
    },
    {
        src:       'images/sliders/imagemGrande/Project_20190516_0365.jpg',
        thumbnail: 'images/sliders/imagemGrande/Project_20190516_0365.jpg',
        caption:    "PropTypes.string "         
    },
    {
        src:       'images/sliders/imagemGrande/Project_20190516_0366.jpg',
        thumbnail: 'images/sliders/imagemGrande/Project_20190516_0366.jpg',
        caption:    "PropTypes.string "         
    },
    {
        src:       'images/sliders/imagemGrande/Project_20190516_0367.jpg',
        thumbnail: 'images/sliders/imagemGrande/Project_20190516_0367.jpg',
        caption:    "PropTypes.string "         
    },
    {
        src:       'images/sliders/imagemGrande/Project_20190516_0369.jpg',
        thumbnail: 'images/sliders/imagemGrande/Project_20190516_0369.jpg',
        caption:    "PropTypes.string "         
    },
    {
        src:       'images/sliders/imagemGrande/Project_20190516_0383.jpg',
        thumbnail: 'images/sliders/imagemGrande/Project_20190516_0383.jpg',
        caption:    "PropTypes.string "         
    },
    {
        src:       'images/sliders/imagemGrande/Project_20190516_0386.jpg',
        thumbnail: 'images/sliders/imagemGrande/Project_20190516_0386.jpg',
        caption:    "PropTypes.string "         
    },
    {
        src:       'images/sliders/imagemGrande/Project_20190516_0391.jpg',
        thumbnail: 'images/sliders/imagemGrande/Project_20190516_0391.jpg',
        caption:    "PropTypes.string "         
    },
    {
        src:       'images/sliders/imagemGrande/Project_20190516_0393.jpg',
        thumbnail: 'images/sliders/imagemGrande/Project_20190516_0393.jpg',
        caption:    "PropTypes.string "         
    },
    {
        src:       'images/sliders/imagemGrande/Project_20190516_0396.jpg',
        thumbnail: 'images/sliders/imagemGrande/Project_20190516_0396.jpg',
        caption:    "PropTypes.string "         
    }

]

class GaleriaControle extends Component{
    state = {
        showModal: false,
        imagem:'/images/sliders/1.jpg',
        currentImagem: 0,
        galeria: [...galeria],
    }
    trocarShowModal = (index)=>{
        this.setState({
            showModal: true,
            currentImagem: index,
            imagem: this.state.galeria[index]
        })
    }

    trocarImagem(index){
   
    }
    voltar = ()=>{
        let prev = this.state.currentImagem - 1;
        console.log(this.state.currentImagem, this.state.galeria.length)

        if(this.state.currentImagem <= 0)
        {
            prev = this.state.galeria.length -1
        }
        this.setState({
            imagem: this.state.galeria[prev],
            currentImagem: prev,
        }) 
    }

    f_fecharModal = ()=>{
        this.setState({
            showModal: false,
        }) 
    }
    proximo = ()=>{
        let next = this.state.currentImagem + 1;
        console.log(this.state.currentImagem, this.state.galeria.length)

        if(this.state.currentImagem >= (this.state.galeria.length - 1))
        {
            next = 0
        }
        this.setState({
            imagem: this.state.galeria[next],
            currentImagem: next,
        }) 

    }
    render(){
        console.log(this.state)
        return(
            <div className="galeriaControle" id="galeria">

                <ModalGaleria show={this.state.showModal} 
                    f_Modal={()=>{this.trocarShowModal(false)}}
                    f_fechar={this.f_fecharModal}
                    foto={this.state.imagem}
                    f_Voltar={this.voltar}
                    f_Proximo={this.proximo}
                />
                {/*<h3 className="text-center titulo-box text-atual">{this.props.nome}</h3>*/}
                <div className="fotos galeria-container mg-80-top">

                    {
                        this.state.galeria.map((foto, i)=>{
                            return <ImagensModal srcImage={foto.thumbnail} key={i} f_modal={()=>{this.trocarShowModal(i)}}/>
                        })
                    }
                </div>
            </div>
        )
    }
}

GaleriaControle.propTypes = {
    nome: PropTypes.string.isRequired,
    galeria: PropTypes.arrayOf(
        PropTypes.shape({            
            src: PropTypes.string.isRequired,
            thumbnail: PropTypes.string.isRequired,
            caption: PropTypes.string            
        })
    )
}


export default GaleriaControle