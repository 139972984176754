import axios from 'axios'
import { _API, _EMPRESA} from '../../config/config'

export async function pegarWenario(cms_id_conteudo = null) {

    let request = await axios.post(`${_API}/leituraSliderConteudo`, {
        cad_empresa: _EMPRESA,
        cms_id_categoria: "0007",
        cms_id_usuario: localStorage.getItem("_IDUSER"),
        cms_id_conteudo: null
    })
        .then(response => {

            return response.data[0]
        })
    return {
        type: "WEBNARIO",
        payload: request
    }
}


export async function pegarZoom(cms_id_conteudo = null) {

    let request = await axios.post(`${_API}/leituraSliderConteudo`, {
        cad_empresa: _EMPRESA,
        cms_id_categoria: "0008",
        cms_id_usuario: localStorage.getItem("_IDUSER"),
        cms_id_conteudo: null
    })
        .then(response => {

            return response.data[0]
        })
    return {
        type: "ZOOM",
        payload: request
    }
}


