import React from 'react'
const Footer = ()=>{
    return(
        <footer className="rodape"> 
          <div className="container">
            <div className="bg-metanoia">
              <div className="row text-center logos" style={{display:'flex', alignItems:'center'}}>
                <div className="col-sm-6"><a href="http://www.metanoia.com.br/" rel="noopener noreferrer"  target="_blank" style={{float: 'left'}}><img className="img-center center-block" src="/images/logo-metanoia.png" alt="Simplesnologia" height="30px" /></a></div>              
                <div className="col-sm-6"><a href="http://happybiz.com.br/" rel="noopener noreferrer"  target="_blank" style={{float: 'right'}}><img className="img-center center-block" src="https://s3-sa-east-1.amazonaws.com/happybiz-web/HappyBiz/logo-branco.png" alt="Simplesnologia" height="30px" /></a></div>
              </div>
            </div>
          </div>
        </footer>
    )
}

export default Footer