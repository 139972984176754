import React from 'react'

const PassInput = (props) =>
<div className="form-group pd-20-top">
<div className="input-group input-group-lg">
    <label className="input-group-addon glyphicon glyphicon-user text-main" htmlFor="txtUsuario"></label>
    <input className="form-control login-futura" type="text" placeholder="Usuário" aria-describedby="sizing-addon1" id="txtUsuario" onChange={props.handleInputEmail}/>
</div>
</div>

export default PassInput