import React, { Component } from 'react'
import Layout from '../../components/layout/indexObra'
import ContainerWithRow from '../../components/ui/bootstrap/ContainerWithRow'
import Loader from '../../components/ui/loaderView'
import Conteudo from '../../components/conteudo/Conteudo'   
import ListBoxesInspiracao from '../../components/boxes/obra/boxInspiracao'
import Page from '../../components/page/Page'
import GaleriaVideos from '../../components/galeriaVideos/galeriaVideos'

//'../../components/boxes/obra/boxInspiracao'

// ACTIONS: Atualizar o state da aplicação
import { listarGeral, listarDadosObra, 
    leituraSliderConteudo, listarConteudos, leituraConteudo, limparConteudo, listarVideos } from '../../store/actions/action.obra'
import { connect } from 'react-redux'
class InspiracaoPage extends Component {
    state = {
        loading: true,
        show: false,
        showConteudo: false,
    }
    async componentDidMount() {
        await this.props.pegarDadosObras(" ")
        this.change()
        this.props.pegarNoticiasGerais()
        this.props.pegarConteudos()
        this.props.listaDeVideos()
      
    }
    change =()=>{
        this.setState({
            loading: false
        })
    }


    f_modal_conteudo = (valor) => {
        if(valor === false){
            this.props.limparConteudoPagina()
        }
        this.setState({
            showConteudo: valor
        })
    }
    f_carregarConteudo(id){
        this.f_modal_conteudo(true)
        this.props.pegarConteudoUnico(id);        
    }

    render() { 
        return (            
            <Layout {...this.props}>
                {this.state.loading ?                 
                    <Loader /> : 
                    <Page titulo="Inspirações" classeContainer="inspiracao">
                        <ContainerWithRow fluid={false} classeContainer="pd-80-top">
                            <ListBoxesInspiracao 
                                f_abrir={() => { this.f_modal(true) }} 
                                noticiasGeral={this.props.noticias_g}
                                podCasts={this.props.podcasts}
                                carregarConteudo={this.f_carregarConteudo.bind(this)}
                                abrirGaleria={()=>this.f_modal()}
                                videos={this.props.video}
                            />
                        </ContainerWithRow>
                    </Page>
                }
            {/* Modal do Basilar */}
            <Conteudo show={this.state.showConteudo} 
                f_fechar_conteudo={() => { this.f_modal_conteudo(false) }} 
                conteudo={this.props.pageConteudo}/>
            </Layout>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        noticias_g: state.obra.noticiasGerais,
        podcasts: state.obra.dados[0] ? state.obra.dados[0].LINK_PODCAST : null,
        conteudosObra: state.obra.conteudos,
        noticiasObra: state.obra.conteudos,
        dadosObra: state.obra.dados[0],
        pageConteudo: state.obra.conteudoPagina,
        video: state.obra.videos
        
    }
}
 
const mapActionsToDispatch = (dispatch) => {
    return {
        pegarNoticiasGerais: (cms_id_conteudo, cms_id_categoria,cms_id_secao) => dispatch(listarGeral(cms_id_conteudo, cms_id_categoria,cms_id_secao)),
        pegarDadosObras: (texto) => dispatch(listarDadosObra(texto)),
        pegarLeituraSliderConteudo: () => dispatch(leituraSliderConteudo()),
        pegarConteudos: ()=> dispatch(listarConteudos()),
        pegarConteudoUnico: (id_conteudo) => dispatch(leituraConteudo(id_conteudo)),
        limparConteudoPagina: ()=> dispatch(limparConteudo()),
        listaDeVideos: ()=> dispatch(listarVideos())
    }
}
export default connect(mapStateToProps, mapActionsToDispatch)(InspiracaoPage)