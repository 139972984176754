import React from 'react'

const PassInput = (props)=>
<div className="form-group">
    <div className="input-group input-group-lg">
        <label className="input-group-addon glyphicon glyphicon-asterisk text-main" htmlFor="txtSenha"></label>
        <input className="form-control input-lg login-futura" placeholder="Senha" type="password" id="txtSenha" onChange={props.handleInputPass} onBlur={props.enabledButton}/>
    </div>
</div>

export default PassInput