import React from 'react'

const bodyMetagram = 
(props)=>{
    console.log(props.source)
    return(
        <div className="body-box tipo-full" id="video">
            <div className="embed-responsive embed-responsive-16by9">
                <video className="embed-responsive-item" src={props.source} autoPlay controls={true} type='video'></video>
            </div>
        </div>
    );
}

export default bodyMetagram