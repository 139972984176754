import axios from 'axios'
import { _API, _EMPRESA} from '../../config/config'

export async function pegarAlertas() {

    let url = `${_API}/alerta/?cad_empresa=${_EMPRESA}&cad_filial=0001&cms_id_usuario=${localStorage.getItem('_IDUSER')}`

    let request = await axios.get(url)
        .then(response => {
            return response.data
        })
    return {
        type: "ALERTAS",
        payload: request
    }
}


export async function apagarAgenda() {

    return {
        type: "APAGAR_MODAL",
        payload: true
    }
}