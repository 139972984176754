import React, { Component } from 'react'
import Layout from '../../components/layout/indexObra'
import ContainerWithRow from '../../components/ui/bootstrap/ContainerWithRow'
import Loader from '../../components/ui/loaderView'
import Conteudo from '../../components/conteudo/Conteudo'
import Page from '../../components/page/Page'
import BoxLivretes from '../../components/boxes/obra/boxLivretes'
import { connect } from 'react-redux'
// ACTIONS: Atualizar o state da aplicação
import { listarGeral, listarDadosObra, 
    leituraSliderConteudo, listarConteudos, leituraConteudo, limparConteudo, listarCaixaDeFerramentas } from '../../store/actions/action.obra'

class Ferramentas extends Component {
    state = {
        loading: true,
        show: false,
        showConteudo: false
    }
    async componentDidMount() {
        await this.props.pegarDadosObras(" ")        
        await this.props.f_listarCaixaDeFerramentas()
        this.change()
    }
    change =()=>{
        this.setState({
            loading: false
        })
    }

    f_modal_conteudo = (valor) => {
        if(valor === false){
            this.props.limparConteudoPagina()
        }
        this.setState({
            showConteudo: valor
        })
    }
    f_carregarConteudo(id){
        this.f_modal_conteudo(true)
        this.props.pegarConteudoUnico(id);        
    }
    render() { 
        return (            
            <Layout {...this.props}>
                <Page>
                    {this.state.loading ?                 
                        <Loader /> : 

                            <div className="Livretes">
                                <ContainerWithRow fluid={false} classeContainer="pd-80-top">
                                    <BoxLivretes 
                                        nome="Metodologias"
                                        f_abrir={() => { this.f_modal(true) }} 
                                        noticiasGeral={this.props.ferramentas}
                                        carregarConteudo={this.f_carregarConteudo.bind(this)}
                                    />
                                </ContainerWithRow>

                                {/* Modal do Basilar */}
                                <Conteudo show={this.state.showConteudo} 
                                    f_fechar_conteudo={() => { this.f_modal_conteudo(false) }} 
                                    conteudo={this.props.pageConteudo}/>
                            </div> 
                    

                    }
            </Page>
            </Layout>
            
        )
    }
}

const mapStateToProps = (state) => {
    return {
        dadosObra: state.obra.dados[0],
        pageConteudo: state.obra.conteudoPagina,
        ferramentas: state.obra.ferramentas
        
    }
}



const mapActionsToDispatch = (dispatch) => {
    return {
        pegarNoticiasGerais: (cms_id_conteudo, cms_id_categoria,cms_id_secao) => dispatch(listarGeral(cms_id_conteudo, cms_id_categoria,cms_id_secao)),
        pegarDadosObras: (texto) => dispatch(listarDadosObra(texto)),
        f_listarCaixaDeFerramentas:(cms_id_conteudo, cms_id_categoria,cms_id_secao) => dispatch(listarCaixaDeFerramentas(cms_id_conteudo, cms_id_categoria,cms_id_secao)),
        pegarLeituraSliderConteudo: () => dispatch(leituraSliderConteudo()),
        pegarConteudos: ()=> dispatch(listarConteudos()),
        pegarConteudoUnico: (id_conteudo) => dispatch(leituraConteudo(id_conteudo)),
        limparConteudoPagina: ()=> dispatch(limparConteudo()),
    }
}

export default connect(mapStateToProps, mapActionsToDispatch)(Ferramentas)

