const UI = (state ={
    erro: {tem: false, msg: ""}
}, action)=>{
    switch (action.type) {
        case "TEM_ERRO":
            return{
                ...state,
                erro: {
                    tem: true,
                    msg: action.payload
                }
            }
        case "LIMPAR_ERRO":
            return{
                ...state,
                erro:false
            }    
        default:
            return state
    }
}

export default UI