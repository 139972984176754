import React from 'react'
import PropTypes from 'prop-types'
import './page.css'

const Page = ( props ) =>{
    return(

        <div className={`pageMetaconexao ${props.className}`}>
            <h2 className="text-center titulo-box text-atual">{props.titulo}</h2>
            {props.children}
        </div>

    )
}

Page.propTypes = {
    className: PropTypes.string,
    titulo: PropTypes.string.isRequired
}

export default Page