import React from 'react'
import Headerbox from './header'
import Bodybox from './body'
import Footerbox from './footer'
import PropTypes from 'prop-types'



class MetagramItem extends React.Component{
    state ={
        deletar: false,
        deletado: false
        
    }
    deletar = (e)=>{
        e.stopPropagation()
        this.setState({
            deletar: !this.state.deletar
        })
    }
    deletado = (e) =>{
        e.stopPropagation()
        this.setState({
            deletado: true
        })    
    }
    render(){
        return (        
            <div className={`col-md-8 col-md-offset-2 col-sm-12 deletarElemento ${this.state.deletado ? "deletado" : null}`}>
                <div className={`metagramItem`}>
                    {this.state.deletar ?  
                    <div className="deletar">
                        <button className="btn btn-lg btn-danger"  onClick={(e)=> {
                            e.stopPropagation()
                            this.deletado(e)
                            this.props.deletarFedd(this.props.idPublicacao)

                        }}>Confirmar</button>
                        <button className="btn btn-lg btn-success " onClick={this.deletar}>Cancelar</button>
                    </div> : null}
                    <Headerbox 
                        nome={this.props.nome} 
                        obra={this.props.obra} 
                        foto={this.props.fotoPerfil} 
                        idUsuario={this.props.idUsuario} 
                        f_deletar={this.deletar}
                    />
                    <Bodybox 
                        tipo={this.props.tipo} 
                        source={this.props.source}
                        cor={this.props.cor}
                        formatoArquivoPublicacao={this.props.formatoArquivoPublicacao}
                    />
                    <Footerbox  
                        tipo={this.props.tipo} 
                        comentarios={this.props.comentarios} 
                        click={this.props.click} 
                        desc={this.props.desc} 
                        obra={this.props.obra} 
                        aviso={this.state.avisar} 
                        email={()=>this.aviso()}
                    />
                </div>
            </div>
        )
    }

}

MetagramItem.propTypes = {
    tipo: PropTypes.string.isRequired,
    source: PropTypes.string.isRequired
}



export default MetagramItem