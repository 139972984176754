import React from 'react'
import ItemComentario from './itemComentario'
import {connect} from 'react-redux'
const Comentarios = (props) =>{
    const conteudo = props.conteudo
    if(!conteudo)
        return null
    let comentarios = []

    if(conteudo.Comentario){
        comentarios = JSON.parse(conteudo.Comentario)
    }

    return(
    <div className="lista-respostas">
        {comentarios.map((comentario, i)=>{
            if(comentario.COMENTARIO_NIVEL_2.length > 0){
                return(
                    <ItemComentario key={Math.random() * i} comentario={comentario} conteudo={conteudo}>
                        {
                            comentario.COMENTARIO_NIVEL_2.map((resposta)=>{
                            return <ItemComentario key={Math.random() * i} nivel={2} comentario={resposta} conteudo={conteudo}/>
                            })
                        } 
                    </ItemComentario>
                )
            }else{
                return <ItemComentario key={Math.random() * i} comentario={comentario}/>
            }
            
        })}        
    </div>
    )
}

const mapStateToProps = (state) => {
    return {
        conteudo: state.obra.conteudoPagina[0]         
    }
}

export default connect(mapStateToProps, null)(Comentarios)