import React from 'react'
import './forum.css'
import { Link } from 'react-router-dom'


const Topico = (props) =>{
    return(
        <div className="col-lg-4 col-md-6 col-sm-12" style={{display:"flex"}}>
            <div className="forum">
                <Link>
                    <div>
                        <div className="infoTitulos">
                            <h5 className="text-main text-left">Topico Devir</h5>
                        </div>
                        <div className="infoUltimaMsg">
                            <img src="/images/usuario.png" alt="Usuario"/>
                            <div>
                                <p className="bold text-left">Ultima mensagem por:</p>
                                <p className="italic text-left">Isaias, <br/>23/09/2015 10:30h</p>
                            </div>
                        </div>
                    </div>
                    <hr/>
                    <div className="infoTopico">
                        <div className="infoQuant">
                            <div>
                                <i className="material-icons">insert_comment</i>
                                <p> 300</p>
                            </div>
                            <div>
                                <i className="material-icons">visibility</i>
                                <p> 400</p>
                            </div>
                        </div>
                        <div>
                            <i className="material-icons">arrow_forward</i>
                        </div>
                    </div>
                </Link>
            </div>
        </div>

    )
}

export default Topico