import React from 'react'
import PropTypes from 'prop-types'
import Container from './Container'
import Row from './Row'
const ContainerWithRow = (props)=>{
    return(
        <Container fluid={props.fluid} classecontainer={props.classecontainer} >
            <Row>
                {props.children}
            </Row>
        </Container>
    )
}
ContainerWithRow.propTypes = {
    fluid: PropTypes.bool,
    classecontainer: PropTypes.string
}

export default ContainerWithRow