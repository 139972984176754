import React, {Component} from 'react'
import PropTypes from 'prop-types'
import Backdrop from '../../../components/ui/modal/backdrop'
import Msg from '../../../components/ui/alert/alert'
import BtnLoader from '../../../components/btn/BtnLoader'
import {_API, _EMPRESA} from '../../../config/config'
import axios from 'axios'

class AtualizarSenha  extends Component{
  state = {
    senha: "",
    confirmarSenha: "",
    senhaAntiga: "",
    succsess  : false,
    isLoading : false

  }
  gerarMsg(tipo, msg){
    switch(tipo){
      case "danger":
        return <Msg msg={msg} type="danger"/> 
      case "success":
        return <Msg msg={msg} type="success"/> 
      default :
        return <Msg msg="Preencha o campo abaixo e enviaremos instruções para você redefinir sua senha por e-mail." type="info"/> 
    }
  }
  f_isLoading =(value)=> {
    this.setState({
      isLoading: value
  })   
  }
  trocarSenha = (e) =>{
    e.preventDefault()
    this.f_isLoading(true);

    if(this.state.senha !== this.state.confirmarSenha){
        this.setState({
            err: "Senhas não conferem."
        })
        return this.f_isLoading(false);

    }else if(this.state.senha.length < 4)
    {
        this.setState({
            err: "A senha precisa de ao menos três caracteres"
        }) 
        return this.f_isLoading(false);
    }

    axios.post(`${_API}/trocarSenha`, {
      cad_empresa : _EMPRESA,
      cms_id_usuario: localStorage.getItem("_IDUSER"),
      cms_senha_nova: this.state.senha,
      cms_senha_antiga: this.state.senhaAntiga
    })
    .then((result)=>{
        var that = this;
        const retorno = result.data[0].RETORNO.split("|")
        const logado  = parseInt(retorno[0]);

        if(logado === 1){
            this.setState({
                succsess: true
            })

            this.f_isLoading(false)

           
            if(this.props.reload)
            {
              setTimeout(()=>{
                that.props.history.push('/obra')
              }, 2000)
            }
            else{
              setTimeout(()=>{
                this.props.f_fechar()
              }, 2500)

            }
        }
        else if(logado === 0){
            this.setState({
                err: retorno[1]
            })
            return this.f_isLoading(false)
        }
    })
  }
  inputSenha = (e) =>{
    const senha = e.target.value;       
    this.setState({
        senha: senha
    })
  }
  inputSenhaAntiga = (e) =>{
    const senha = e.target.value;       
    this.setState({
        senhaAntiga: senha
    })
  }
  inputSenhaConfirmar = (e)=>{
    const confirmar = e.target.value;   
    
    this.setState({
        confirmarSenha: confirmar
    })    
  }
  
  render(){
    let msg = null
    if(this.state.succsess)
    {
      msg = this.gerarMsg("success", "Senha alterada com sucesso.")
    }
    else if(this.state.err){
      msg = this.gerarMsg("danger", this.state.err)
    }

    return(
    <Backdrop classBd='backdrop-galeria' modal={this.props.show} f_fechar={this.props.f_fechar}>
      <div className="container">
          <div className="row modal-senha">
              <div className="col-sm-6">
                  <div className="alterar-senha">
                      <h4 className="text-main text-center">Redefinir senha</h4>
                      <form action="" autoComplete="off" onSubmit={this.trocarSenha}>
                        <div className="form-group pd-20-top">
                            {msg}
                            <input className="form-control input-lg" type="password" placeholder="Senha Antiga" value={this.state.senhaAntiga} onChange={this.inputSenhaAntiga} autoComplete="new-password"/>                            
                            <input className="form-control input-lg" type="password" placeholder="Nova senha" value={this.state.senha} onChange={this.inputSenha} autoComplete="false"/>
                            <input className="form-control input-lg" type="password" placeholder="Repetir nova senha" value={this.state.confirmarSenha} onChange={this.inputSenhaConfirmar} autoComplete="false"/>
                        </div>
                        <BtnLoader f_func={(e)=>{this.trocarSenha(e)}} labelName="Confirmar" isLoading={this.state.isLoading}/>

                      </form>
                  </div>
              </div>
          </div>
      </div>
    </Backdrop>
    )
  }
}

AtualizarSenha.propTypes = {
  show: PropTypes.bool.isRequired,
  f_fechar: PropTypes.func.isRequired,
  reload: PropTypes.bool
}


export default AtualizarSenha