const feed = null;
const Metagram = (state = {
    feed: feed
}, action) => {
    switch (action.type) {
        case "FEED":
            return { ...state, feed: action.payload }
        case "DELETE_FEED":
            return state
        default:
            return state
    }
}

export default Metagram