import React, { Component } from 'react';
/**
 * Objetivo: Autenticar e verificar se o usuario tem autorização para o uso
 * */

// Autorização

export default function(ComponentRender){
    class AuthComponent extends Component{
        state = {
            loading: true
        }
        componentDidMount(){
            // verifica se existe o usuario no localStorage
            // se sim direciona para a página em si
            let user = window.localStorage.getItem('_IDUSER')
            if(!user){
                this.props.history.push('/login')
            }
        }

        render(){

            return <ComponentRender {...this.props}  />
            
        }
    }

    return AuthComponent
}