import React from 'react'
import Box from '../boxSimples'
import ItemBox from '../listBox/listBoxItem'
import './inspiracao.css'
import Loader from '../../ui/loader'
import ItemBoxVideo from '../listBox/listaVideos'

const ListBox = (props) =>{
    return(
    <div className="inspiracao">
        <div className="box">
            <div className="col-xs-12 col-md-12 todos-podcasts">
                <h4 className="titulo-box text-atual">Artigos</h4>
                <Box tipo="comLimite" height={500} width={100}>
                {
                    props.noticiasGeral.length > 0 ?
                    props.noticiasGeral.map(function(item, i){                           
                        return(
                            <ItemBox info={item} key={i} func={()=> props.carregarConteudo(item.idConteudo)} />                           
                            )
                    })
                    :<Loader cor={true}/>
                }               
                </Box>
            </div>
             {/*<div className="col-xs-12 col-md-12 mg-60-top todos-artigos">
                <h4 className="titulo-box titulo-artigo text-atual">Videos</h4>
                <Box tipo="unico" width={100}>
                    <div class="embed-responsive embed-responsive-16by9">
                        <iframe class="embed-responsive-item" src="https://fast.wistia.net/embed/iframe/7j0svt3whn?videoFoam=true"></iframe>
                    </div>
                </Box>
            </div>
           
            <div className="col-xs-12 col-md-12 mg-60-top todos-artigos">
                <h4 className="titulo-box titulo-artigo text-atual">Videos</h4>
                <Box tipo="comLimite" height={500} width={100}>
                {
                    props.videos.length > 0 ?
                    props.videos.map(function(item, i){                           
                        return(
                            <ItemBoxVideo info={item} key={i} func={()=> props.carregarConteudo(item.idConteudo)}/>
                        )
                    })
                :<Loader cor={true}/>
                }
                </Box>
            </div>*/}
        </div> 
    </div>

    )
}

export default ListBox