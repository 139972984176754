import React, {Component} from 'react'
import Footer from './footer-home'
import Header from './Header'
import './layout.css'
class Layout extends Component{
    render(){
        return(
            <div className="metaconexaoAPP">
            <div className="tudo">

                <Header />

                {this.props.children}

            </div>
            <Footer/>
          </div>
        )
     }
}

export default Layout
