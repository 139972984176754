const superAdmin = (state = {
    empresas: []
}, action) => {
    switch (action.type) {
        case "CARREGAR_EMPRESA":
            return { ...state, empresas: action.payload }
        case "RELOAD_APP":
            return{...state}
        default:
            return state
    }
}

export default superAdmin