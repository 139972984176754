import React, {Component} from 'react'
import {connect}from 'react-redux'
import './erro.css'
class ControleDeErro extends Component{

    static getDerivedStateFromError(error){
        console.log(error)
    }

    componentDidCatch(error, info){
        console.log(error, info);
    }
    render() {
        if (this.props.erro.tem) {
          // You can render any custom fallback UI
          return (
              <React.Fragment>
                  <div className="errorGeralMsg">
                    <p>{this.props.erro.msg}</p>
                  </div>

                  {this.props.children}
              </React.Fragment>
          )
        }    
        return this.props.children;  
    }
}

const mapStateToProps = (state)=>{
    return{
        erro: state.ui.erro
    }
}

export default connect(mapStateToProps)(ControleDeErro)