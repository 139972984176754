import React from 'react'
import PropTypes from 'prop-types'
const Container = (props)=>{

    let containerclass = props.fluid ? "container-fluid " : "container " 
    containerclass += props.classecontainer

        
    return(
        <div className={containerclass}>
            {props.children}
        </div>
    )
}
Container.propTypes = {
    fluid: PropTypes.bool.isRequired,
    classecontainer: PropTypes.string
}

export default Container