import {combineReducers} from 'redux'
import obra from './red.obra'
import webnario from './red.webnar'
import metagram from './red.metagram'
import apoio from './red.apoio'
import ui from './ui'
import alertas from './red.alertas'
import superUser from './red.superUser'
import realizacao from "./red.realizacao"
const rootReducers = combineReducers({
    obra,
    webnario,
    metagram,
    apoio,
    ui,
    alertas,
    superUser,
    realizacao
})
export default rootReducers