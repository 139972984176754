import React from 'react'
import Item from './itens/itemResultado'
const ContentBasilar = (props) =>{
  return(
        <div className="wrapperBasilar boxDevir clearfix">
            <div className="modalBasilar">
                <div className="basilarW" >           
                    <div className="col-md-4 col-sm-12 col-xs-12 basiliar-wraper mg-20-top">
                        <div className="col-sm-12">
                            <h4 className="text-left bold">Filosofia</h4>
                            <hr/>  
                            <div className="item-box clearfix basilar">                              
                                <Item nome="Propósito" porc={props.resultado.VALOR_PROPOSITO_FILOSOFIA} className="primeiro-filho"/>
                                <Item nome="Modelo de liderança" porc={props.resultado.VALOR_MODELO_LIDERANCA_FILOSOFIA}/>
                                <Item nome="Valores" porc={props.resultado.VALOR_VALORES_FILOSOFIA}/>
                            </div>
                        </div>
                        <div className="col-sm-12">
                            <h4 className="text-left bold">Estratégia</h4>
                            <hr />
                            <div className="item-box clearfix basilar">
                                <Item nome="Foco" porc={props.resultado.VALOR_DCAUSAL_ESTRATEGIA}/>
                                <Item nome="Intenções" porc={props.resultado.VALOR_DCAUSAL_ESTRATEGIA}/>
                                <Item nome="Competências" porc={props.resultado.VALOR_DPOTENCIAL_ESTRATEGIA}/>
                                <Item nome="Diferenciais" porc={props.resultado.VALOR_DECONOMICA_ESTRATEGIA}/>
                            </div>
                        </div>         
                    </div>

                    <div className="col-md-4 col-sm-12 visible-sm-block visible-md-block visible-lg-block">
                        <img className="img-responsive center-block" src="images/gps-cultural-2.png" alt="GPS Cultural"/>
                    </div>


                        <div className="col-md-4 col-sm-12 col-xs-12 basiliar-wraper">
                            <div className="col-sm-12">
                                <h4 className="text-left bold">Método</h4>
                                <hr />
                                <div className="item-box clearfix basilar">
                                    <Item nome="Roda do Aprendizado" porc={props.resultado.VALOR_RODA_APRENDIZADO_METODO}  className="primeiro-filho"/>
                                    <Item nome="Conversa de Valor" porc={props.resultado.VALOR_CONVERSA_VALOR_METODO}/>
                                    <Item nome="Consenso" porc={props.resultado.VALOR_CONSENSO_METODO}/>
                                    <Item nome="Experiência" porc={props.resultado.VALOR_EXPERIENCIA_METODO}/>

                                    <Item nome="Estrutura Sistêmica" porc={props.resultado.VALOR_ESTRUTURA_SISTEMICA_METODO}/>
                                    <Item nome="Ilha das Competências" porc={props.resultado.VALOR_ILHA_COMPETENCIAS_METODO}/>
                                    <Item nome="Balanço das Riquezas" porc={props.resultado.VALOR_BALANCO_RIQUEZAS_METODO}/>
                                    <Item nome="Metaprocesso" porc={props.resultado.VALOR_METAPROCESSO_METODO}/>

                                    <Item nome="Metaprojeto" porc={props.resultado.VALOR_METAPROJETO_METODO}/>
                                    <Item nome="Planta de Serviço" porc={props.resultado.VALOR_PLANTA_SERVICO_METODO}/>
                                    <Item nome="Princípio da Linha D´Água" porc={props.resultado.VALOR_PRINCIPIO_LINHA_AGUA_METODO}/>

                                    <Item nome="A Musa" porc={props.resultado.VALOR_MUSA_METODO}/>
                                    <Item nome="Operação Curiosidade" porc={props.resultado.VALOR_OPERACAO_CURIOSIDADE_METODO}/>
                                </div>
                            </div>
                        </div>
                    </div>
                    </div>
                    <div className="col-md-4 col-sm-12 col-xs-12">
                        <div className="legenda">
                            <div className="item-legenda">
                                <p className="numero-legenda" style={{backgroundColor:"rgb(209, 199, 30)"}}>0-60%</p>
                                <p>Cultura em atenção</p>
                            </div>
                            <div className="item-legenda">
                                <p className="numero-legenda" style={{backgroundColor:"rgb(253, 165, 26)"}}>61-80%</p>
                                <p>Cultura em consolidação</p>
                            </div>
                            <div className="item-legenda">
                                <p className="numero-legenda" style={{backgroundColor:"rgb(0, 84, 168)"}}>81-100%</p>
                                <p>Cultura em cultivo</p>
                            </div>
                        </div>
                    </div>
                </div>

  )
}

ContentBasilar.propTypes = {

}


export default ContentBasilar

