import React from 'react'
const Header = (props)=>{
    return(
    <header>
        <div className="img-header headerGeral">
            <div className="container">
            <div className="row suporte-top"> 
                <div className="col-sm-12">
                <div className="logo-skin" />
                </div>
            </div>
            </div>
        </div>
    </header>
    )
}

export default Header