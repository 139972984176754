
import axios from 'axios'
import { _API, _EMPRESA} from '../../config/config'


export async function pegarFeed() {

    const url = `${_API}/publicacao/?cad_empresa=${_EMPRESA}&cms_id_usuario=${localStorage.getItem("_IDUSER")}`

    let request = await axios.get(url)
        .then(response => {
            return response.data
        })
    return {
        type: "FEED",
        payload: request
    }
}


export async function deletarFeed(id) {

    const url = `${_API}/publicacao`

    let request = await axios.delete(url, {data:{
        
        cad_empresa: _EMPRESA,
        cms_id_publicacao: id,
        cms_id_usuario: localStorage.getItem("_IDUSER"),
        
    }})
        .then(response => {
            return response.data
        })
    return {
        type: "DELETE_FEED",
        payload: request
    }
}


