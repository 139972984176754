import React from 'react'
import { NavLink, Link } from 'react-router-dom'
const Header = (props)=>{
    let menu = `display-menu-mob ${props.showMobMenu ? 'menuAberto' : ""}`
    let styleBg = {}
    return(
    <header>
        <div className="img-header">
            <div className="container">
                <div className="row suporte-top"> 
                    <div className="col-sm-12" style={{display:'flex', justifyContent:'center'}}>
                        <Link to="/obra">
                            <div className="logo-intranet"></div>
                        </Link>
                    </div>
                </div>
                <div className="row">
                    <nav className="menu visible-lg-block visible-md-block">
                        <ul>
                            <li>
                                <NavLink to="/obra">Programa</NavLink>
                                <div className="seta-menu"></div>
                            </li>
                            <li>
                                <NavLink to="/mp-da-travessia">Ponto de Vista</NavLink>
                                <div className="seta-menu"></div>
                            </li>
                            <li>
                                <NavLink to="/inspiracoes">Inspirações</NavLink>
                                <div className="seta-menu"></div>
                            </li>
                            <li>
                                <NavLink to="/livretes">Conteúdos</NavLink>
                                <div className="seta-menu"></div>
                            </li>
                            <li>
                                <NavLink to="/ferramentas">Metodologias</NavLink>
                                <div className="seta-menu"></div>
                            </li>
                            <li>

                                <NavLink to="/metagram">Compartilhamento</NavLink>
                                <div className="seta-menu"></div>
                            </li>
                            <li>
                                <NavLink to="/orientacao-e-apoio">Realizações</NavLink>
                                <div className="seta-menu"></div>
                            </li>
                            <li onClick={props.logOut}>
                                <a href="#">SAIR</a>
                                <div className="seta-menu"></div>
                            </li>
                        </ul>
                    </nav>
                    <nav className="menu-mob visible-sm-block visible-xs-block" onClick={()=>{props.f_mob(true)}}>
                        <span className="icone-menu"></span>
                        <span className="icone-menu"></span>
                        <span className="icone-menu"></span>
                    </nav>
                    <div className={menu}>
                        <ul>
                            <li>
                                <NavLink to="/obra">Programa</NavLink>
                                <div className="seta-menu"></div>
                            </li>
                            <li>
                                <NavLink to="/mp-da-travessia">Ponto de Vista</NavLink>
                                <div className="seta-menu"></div>
                            </li>
                            <li>
                                <NavLink to="/inspiracoes">Inspirações</NavLink>
                                <div className="seta-menu"></div>
                            </li>
                            <li>
                                <NavLink to="/livretes">Conteúdos</NavLink>
                                <div className="seta-menu"></div>
                            </li>
                            <li>
                                <NavLink to="/ferramentas">Metodologias</NavLink>
                                <div className="seta-menu"></div>
                            </li>
                            <li>
                                <NavLink to="/metagram">Compartilhamento</NavLink>
                                <div className="seta-menu"></div>
                            </li>
                            <li>
                                <NavLink to="/orientacao-e-apoio">Realizações</NavLink>
                                <div className="seta-menu"></div>
                            </li>                                    
                            <li onClick={props.logOut}>
                                <a href="#">SAIR</a>
                                <div className="seta-menu"></div>
                            </li>
                        </ul>
                        <div className="fechar" onClick={()=>{props.f_mob(false)}}>
                            <img src="/images/fechar.png" alt="Fechar menu"/>
                        </div>
                    </div>
                <hr />
                </div>
            </div>
        </div>
    </header>
    )
}


export default Header