import React from 'react'
import Box from '../boxSimples'
import ItemBox from '../listBox/listBoxItemLivrete'
import './inspiracao.css'


const BoxLivretes = (props) =>{

    return(
    <div className="inspiracao">
        <h2 className="text-center titulo-box text-atual">{props.nome}</h2>
        <div className="box">
            <div className="col-xs-12 col-md-12 todos-podcasts">
                <Box tipo="comLimite" height={700} width={100}>
                <div className="livretes">
                    {
                        props.noticiasGeral.length > 0 ?
                        props.noticiasGeral.map(function(item, i){                           
                            return(
                                <ItemBox info={item} key={i} func={()=> props.carregarConteudo(item.idConteudo)} />                           
                                )
                        })
                        :<p>Não temos nenhum livrete ainda para você!</p>

                    }
                </div>               
                </Box>
            </div>

        </div> 
    </div>

    )
}

export default BoxLivretes