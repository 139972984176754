import React, {useState, useEffect} from 'react'
import BtnLoader from '../../components/btn/BtnLoader'
import './apoioCadastrar.css'
import Msg from '../../components/ui/alert/alert'

const ApoioCadastrar = (props) =>{
    if(!props.acao){
      return null
    }
    const acaoConvertida = props.acao.split("|")
    console.log(acaoConvertida)
    const [quantVagas, setQauntVagas]       = useState(parseInt(acaoConvertida[1]))
    const [vagasOcupadas, setvagasOcupadas] = useState(parseInt(acaoConvertida[1] - acaoConvertida[2]))
    const [assentoSelecionado, setarAssentoSelecionado] = useState( parseInt(Math.random() * quantVagas))  
    
    const [assentosArray, setarAssento] = useState([])

    useEffect(function(){
      let novaQuantVagas = null
      novaQuantVagas = parseInt(acaoConvertida[1])
      setQauntVagas(novaQuantVagas)
    })

    useEffect(function(){
      let novaVagasOcupadas = null
      novaVagasOcupadas = parseInt(acaoConvertida[1] - acaoConvertida[2])
      setvagasOcupadas(novaVagasOcupadas)
    })

    useEffect(function(){
      let array = []
      for(let i = 0; i < quantVagas; i++){
          array.push( {
            selecionado: false, 
            preenchido: vagasOcupadas > i ? true : false } )      
      }
      array[assentoSelecionado].selecionado = true
      setarAssento([...array])  
    }, [props.acao, quantVagas, vagasOcupadas])



    function trocarCadeiras(){
      let newArray = assentosArray.map(assento=>{
        assento.selecionado = false;
        return assento
      })

      for(let i = 0; i < assentosArray.length; i++){
        if(i === assentoSelecionado)
        newArray[i].selecionado = true  
        
      }
      setarAssento(newArray)     
    }
    if(!props.abrir)
      return null
    return(
    <>
    <div className={`cadastrarParaPrograma ${props.abrir ? 'aberto' : null}`}>
    <i className="material-icons fecharApoio" onClick={props.fechar}>
      clear
    </i>
      <div className="contentcadeiras">
        <h4>Escolha seu assento:</h4>
        
        <div className="escolha-assento">
          <div className="mapaDeAssentos">
          {assentosArray.map((assento, i)=>{
            let cssClass = "assento"
            if (assento.selecionado){
              cssClass += " selecionado" 
            }else if(assento.preenchido){
              cssClass += " preenchido"
            }
            return(
              <div key={Math.random()} className={`${cssClass}`} onClick={()=>{ 
                if(assento.preenchido) {}else{ setarAssentoSelecionado(i)};trocarCadeiras() }}>{i+1}
              </div>
            )            
          })}
          </div>
        </div>

        </div>

        <div className="informacoesDoCadastro">
          <p>Pronto para delocar?</p>
          <h4>{props.programa.titulo}</h4>
          <p>turma: {props.programa.turma}</p>
          <hr />
          <p className="descricao-encontro">
            Descrição: 
          </p>
          <blockquote>
              {props.programa.desc}
            </blockquote>
          <div className="assentoEscolhi">
            <h5>
              {assentoSelecionado + 1}
            </h5>
            <p>Assento escolhido</p>
          </div>
          <p>
            {props.sucesso ? <Msg msg={"Incrição Realizada com sucesso"} type="success"/>  :  <BtnLoader f_func={props.cadastrar} labelName="CONFIRMAR" isLoading={props.carregando} enabledButton={props.carregando}/> }
            
          </p>
        </div>
    </div> 
    </>    
    )
}

export default ApoioCadastrar