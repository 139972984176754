import React from 'react'

const bodyMetagram = (props)=>{
    return(
        <div className="body-box tipo-full">
            <div className="imagem" style={{backgroundImage: `url(${props.source})`}} ></div>
        </div>
    );
}

export default bodyMetagram