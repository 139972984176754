import React from 'react'
import Backdrop from '../../ui/modal/backdrop'
import ModalItem from '../modal/modalItem'
import Loader from '../../ui/loader/index'
import ApoioCadastrar from '../../apoioCadastrar/apoioCadastrar'
import {_API, _EMPRESA} from '../../../config/config'
import axios from 'axios'

class ConteudoApoio extends React.Component{
  state = {
    modal: false,
    carregando: false,
    err:"",
    id_produto: null,
    localidade: null,
    acao: "",
    sucesso: false,
    vagas: 0,
    assentoEscolhido: null,
    programa:{titulo: "--", turma: "---"}
  }
  btnAcao(agenda, f_){

    const ACAO = agenda.ACAO.split("|")
    const acao = ACAO[0]
    switch (acao) {
      case "0":
          return null
      case "1":
        return <button className="btn btn-main btn-participar" onClick={f_}><i className="material-icons">local_movies</i>Quero participar</button>
      case "2": 
        return <button className="btn btn-success btn-participar" disabled={true}><i className="material-icons">flight_takeoff</i>Decolagem iniciada</button>
      default:
        return <button className="btn btn-main btn-participar" onClick={f_}><i className="material-icons">local_movies</i>Quero participar</button>
    }
  }
  setarState = (id_produto, localidade, agenda)=>{
    console.log(id_produto, localidade, agenda)
    this.setState({
      id_produto,
      localidade,
      agenda
    })
  }

  setarPrograma = (programa)=>{
    this.setState({
      programa
    })
  }

  abrirModal = ()=>{
    this.setState({
      modal: !this.state.modal
    })
  }

  trocarAssento = (id) =>{
    this.setState({
      assentoEscolhido: id
    })
  }

  setarPrograma = (titulo, turma, desc) =>{
    this.setState({
      programa: {
        titulo: titulo,
        turma: turma,
        desc: desc
      }
    })
  } 

  cadastrar = () =>{
    this.setState({carregando: true})
    axios.post(`${_API}/inserirInscricao`, {
      cad_empresa : _EMPRESA,
      cad_filial: "0001",
      crm_id_produto: this.state.id_produto,
      crm_id_localidade: this.state.localidade,
      crm_id_agenda: this.state.agenda,
      cms_id_usuario: localStorage.getItem('_IDUSER'),
    })
    .then((res)=>{
      const result = res.data[0].RESULTADO.split("|")[0]
      if(result === "0")
      {
        this.setState({
          err: "Erro ao tentar gravar, por favor entre em contato conosco."
        })
      }
      else{
        var that =  this
        that.setState({
          carregando: false,
          sucesso: true,
        })


      }
    }) 
  }
  
  render(){
    let descricao = ""
    return(

        <>
        <ApoioCadastrar 
          abrir={this.state.modal} 
          fechar={this.abrirModal}
          cadastrar={this.cadastrar}
          carregando={this.state.carregando}
          sucesso={this.state.sucesso}
          assentoEscolhido={this.state.assentoEscolhido}
          acao={this.state.acao}
          vagas={this.state.vagas}
          trocarAssento={this.trocarAssento}
          programa={this.state.programa} />
      <Backdrop classBd={`backdrop backdrop-galeria ${this.props.modal ? "aberto" : ""}`}  f_fechar={this.props.f_fechar_conteudo}>  
        <>

        {Array.isArray(this.props.modalConteudo) ? 
      
        <div className="modalConteudo" id="Apoio">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <div className="conteudo-box-home center-block clearfix">
                <div className="clearfix ">
                  {this.props.modalConteudo.length > 0 ?
                    this.props.modalConteudo.map((item, index)=>{
                      const agendas = JSON.parse(item.TURMA)
                      return(
                        <div className="agendaWrapper" key={Math.random() + item.CRM_ID_PRODUTO}>
                          <h5 className="bold">{item.CRM_NOME_PRODUTO}</h5>
                          <hr/>
                          <ul className="agendas">                          
                              { agendas.map((agenda, i)=>{
                                
                                return(
                                  <>
                                  <li key={Math.random() * i} className="clearfix">
                                    {agenda.ENCONTROS.map( (encontro, j)=>{
                                      const nome = agenda.CRM_NOME_AGENDA
                                      descricao = encontro.CRM_DESCRICAO_ENCONTRO
                                      return <ModalItem 
                                      nome={nome} key={Math.random() * i+j} data={encontro.CRM_DATA_INICIAL} desc={encontro.CRM_DESCRICAO_ENCONTRO} tipo={encontro.CRM_NOME_TIPO_AGENDA} />
                                    }) 
                                    }
                                    {
                                      this.btnAcao(
                                        agenda, ()=> {
                                        this.setState({
                                          acao:agenda.ACAO,
                                        })
                                        this.setarPrograma(item.CRM_NOME_PRODUTO, agenda.CRM_NOME_AGENDA, descricao)
                                        this.abrirModal()
                                        this.setarState(item.CRM_ID_PRODUTO, item.CRM_ID_LOCALIDADE, agenda.CRM_ID_AGENDA)
                                      })          
                                    }
                                </li>
                                </>
                                )                              
                              })}
                          </ul>
  
                        </div>
                      )
                    }): <h4>Nenhuma Informação para esse programa</h4>}
  
              </div>          
          </div>
        </div>
      </div>    
      : <Loader />}
      </>
      </Backdrop>
      </>
      ) 
  }
}


export default ConteudoApoio