import axios from 'axios'
import { _API, _EMPRESA} from '../../config/config'

export async function pegarAgenda(agrupador = false, id, tipo) {
    let url = ""
    if(agrupador){
        url = `${_API}/orientacao/?cad_empresa=${_EMPRESA}&cad_filial=0001&crm_id_agrupador=${id}&cms_id_usuario=${localStorage.getItem('_IDUSER')}`
    }else{
        url = `${_API}/orientacao/?cad_empresa=${_EMPRESA}&cad_filial=0001&crm_id_produto=${id}&cms_id_usuario=${localStorage.getItem('_IDUSER')}`
    }
    let request = await axios.get(url)
        .then(response => {
            return response.data
        })
    return {
        type: "APOIO_MODAL",
        payload: request
    }
}


export async function apagarAgenda() {

    return {
        type: "APAGAR_MODAL",
        payload: true
    }
}