const Realizacao = (state = {
    arquivos: null,

}, action) => {
    switch (action.type) {
        case "LEITURA_REALIZACAO_CONTEUDO":
            return { ...state, arquivos: action.payload }
        default:
            return state
    }
}



export default Realizacao