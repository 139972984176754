import React, {useState, useEffect} from 'react'
import Respoder from './responder'
import {_API, _EMPRESA}             from '../../config/config'
import {connect}                    from 'react-redux'
import {leituraConteudo}            from '../../store/actions/action.obra'
import axios                        from 'axios'

const Comentarios = (props) =>{
    const comentario = props.comentario
    const img = "images/usuario.png"
    const [mostrar, setarMostrar] = useState(false)
    const [deletar, setarDeletar] = useState(false)

    useEffect(()=>{

        if(!deletar)
            return
        let data = {
          cad_empresa       : _EMPRESA,
          cms_id_conteudo   : props.conteudo.idConteudo,
          cms_id_comentario : comentario.idComentario,
          cms_id_usuario    : localStorage.getItem('_IDUSER'),        
        }            
        axios.delete(`${_API}/conteudoComentario`, {
            data
        })
          .then((res)=>{
            console.log(res)
            const result = res.data[0].RESULTADO.split("|")[0]
            props.pegarConteudoUnico(props.conteudo.idConteudo)
          })
          .catch((err)=>{

          })
    }, [deletar])
    return(
        <>
        <div className={`respostas mg-40-top ${props.nivel === 2 ? 'minhaResposta': ''}`}>{/* minhaResposta */}
            <i className="material-icons" onClick={()=>setarDeletar(!deletar)} style={{display:comentario.idAutor === localStorage.getItem('_IDUSER') ? "block" : " none"}}>cancel</i>
            <div className="respostaFiliado">
                <p className="text-left">{comentario.Mensagem}</p>
            </div> 
            <div className="informacoesForum">
                <div className="inforFiliado">
                <div className="comentImg" style={{backgroundImage: `url(${comentario.fotoPerfil || img})`}}></div>
                <p className="italic text-left">Por: {comentario.nomeAutor}<br/>
                    Em: {comentario.dataComentario}</p>
                </div>
            </div>
            {props.children} 
            {props.nivel === 2 ? 
            null          
            :
            <>
                <div className="add-comentario" onClick={()=>{
                    setarMostrar(!mostrar)
                }}>
                    <img src="/images/add-comentario.png" alt=""/>
                </div>
                {mostrar && <Respoder nivel={2} idComentario={comentario.idComentario}/>}                
            </> 
            }

        </div>
           
        </>
    )
}

const mapActionsToDispatch = (dispatch) => {
    return {
        pegarConteudoUnico: (id_conteudo) => dispatch(leituraConteudo(id_conteudo)),

    }
}

const mapStateToProps = (state) => {
    return {
        conteudo: state.obra.conteudoPagina[0]         
    }
}


export default connect(mapStateToProps, mapActionsToDispatch)(Comentarios)

