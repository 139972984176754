import React, { Component, Fragment } from 'react'
import {connect} from 'react-redux'
import './admin.css'
import { trocarEmpresa, gravarEmpresa } from '../../store/actions/action.superUser' 
class SuperAdmin extends Component{
    state={
        admin:false,
        valor: "0"
    }
    abrirAdmin(){
        this.setState({admin:!this.state.admin})
    }

    componentDidMount(){
        this.props.pegarEmpresas()
    }

    acoplarEmresa = async (e) =>{
        await this.props.gravarEmpresa(e.target.value)
        window.location.reload()
    }

    render(){
        if(!this.props.superUser)
            return null

        let empresaAtual = ''
        this.props.empresas.map( function(empresa){
            if (empresa.EmpresaAtual === 1)
            {
                empresaAtual = empresa.CodigoEmpresa
            }
        })

        return(
            <Fragment>
                <div className={`superAdmin ${this.state.admin ? 'superAdmin-ativo' : " "}`}>
                    <div className="admin">
                        <p className="titulo-admin">Super Administrador</p>
                        <div className="escolherObra">
                            <div className="obra-admin">
                                <p className="text-left">Escolha a obra</p>
                                <div className="form-group">
                                    <select name="obra" id="escolherObra" value={empresaAtual} className="form-control" onChange={this.acoplarEmresa.bind(this)}>
                                        {this.props.empresas.map((empresa, i)=>{
                                            return <option value={empresa.CodigoEmpresa}  selected ={empresa.EmpresaAtual === 1 ? true : false } key={Math.random()* i}>{empresa.NomeEmpresa}</option>
                                        })}                                
                                    </select>
                                </div>
                            </div>
                            <button className="fechar-admin">
                                <i className="material-icons" onClick={()=>this.abrirAdmin()}>cancel</i>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="text-center abrirAdmin">
                    <div className="bg-abrir-Menu-super" onClick={()=>this.abrirAdmin()}>
                        <i className="material-icons">expand_more</i>
                    </div>
                </div>
            </Fragment>
        )
    }
}

const mapStateToProps = (state)=>{
    return{
        superUser: state.obra.dados ? state.obra.dados[0].USUARIO_MASTER : null,
        empresas: state.superUser.empresas
    }
}

const mapActionsToProps = (dispatch) =>{

    return{
        pegarEmpresas: () => dispatch(trocarEmpresa()), 
        gravarEmpresa: (id) => dispatch(gravarEmpresa(id))

    }
}

export default connect(mapStateToProps, mapActionsToProps)(SuperAdmin)