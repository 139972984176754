const Alertas = (state = {
    alertasGerais: [],

}, action) => {
    switch (action.type) {
        case "ALERTAS":
            return { ...state, alertasGerais: action.payload }
        default:
            return state
    }
}



export default Alertas