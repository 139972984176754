import React from 'react';
import PropTypes from 'prop-types'
import './alerta.css'
const Alerta = (props)=>{
    return(
        <div className="notice notice-success" role="alert">
            <small className="bold">Pesquisa</small>
            <h6 className="bold text-left" style={{
                    paddingTop: 0,
                    marginTop: 0,
            }}>{props.alerta.TITULO}</h6>
            <div dangerouslySetInnerHTML={{__html: props.alerta.MENSAGEM }}>
                
            </div>
        </div>
    )
}

Alerta.propType = {
    alerta: PropTypes.object.isRequired
}



export default Alerta