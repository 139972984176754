import React, {Component} from 'react'
import Layout from '../../components/layout/indexObra'
import Metagram from '../../components/metagram/metagram'
import Loader from '../../components/ui/loaderView'
import {connect} from 'react-redux'
import ModalMetagram from '../../components/metagram/modal'
import { pegarFeed, deletarFeed } from '../../store/actions/action.metagram'


//redux
import { listarDadosObra } from '../../store/actions/action.obra'


class metagram extends Component{
    state = {
        loading: true,
        modal: false,
        cometario: false
    }

    change =()=>{
        this.setState({
            loading: false
        })
    }

    deletarFedd = async (id)=>{
        await this.props.deletarFeedUnico(id);
        this.reCarregar()
    }

    f_fechar = ()=> {
        this.setState({
            modal: false
        })
    }

    f_abrirModal = ()=>{
        this.setState({
            modal: true
        })  
    }

    f_comentario = () => {
        this.setState({cometario:true})
    }

    reCarregar = ()=>{
        this.props.pegarFeed();
    }


    async componentDidMount(){
        await this.props.dadosObra();
        this.props.pegarFeed();
        this.change();

    }

    render(){

        return(
            <Layout {...this.props}>
                 {this.state.loading ? <Loader /> : 
                 
                    <Metagram feed={this.props.feed} 
                        f_abrir={this.f_abrirModal}
                        deletarFedd={this.deletarFedd} 
                        comentarios={this.state.cometario} 
                        click={()=>this.f_comentario()}/>
                 } 

                 <ModalMetagram modal={this.state.modal} f_fechar={this.f_fechar} modalConteudo={true}  reCarregar={this.reCarregar}/>                
            </Layout>
        )
    }
}

const mapStateToProps = (state) => {
    console.log(state.metagram.feed)
    return {
        dadosObra: state.obra.dados[0],
        feed: state.metagram.feed
        
    }
}

const mapActionsToProps = (dispatch) =>{
    return{
        dadosObra: () => dispatch(listarDadosObra()),
        pegarFeed: () => dispatch(pegarFeed()),
        deletarFeedUnico: (id) => dispatch(deletarFeed(id))
    }
}

export default connect(mapStateToProps, mapActionsToProps)(metagram)

