import React, {Fragment} from 'react'
import ItemBox from '../listBox/listBoxItem'
import Loader from '../../ui/loader'
import Signa from '../../signa/signa'
const ListBox = (props) =>{
    console.log(props)
    return(
    <Fragment>
        <div className="box mg-30-top" id="desafios">
            {/*<div className="col-xs-12 col-md-2 todos-artigos" id="basilar"  style={{marginTop: 10, marginBottom: 30}}>
                <div>
                    <div className="col-sm-12" style={{margin: "auto"}}>
                        <div className="conteiner-lume text-center">
                            <img className="img-responsive center-block" src="images/logo-padrao.png" alt="GPS Cultural"/>
                        </div>
                    </div>
                </div>
            </div>*/}
            <div className="col-xs-12  col-md-9">

                    {props.MpTravessiaConteudos.length > 0 ?                     
                    props.MpTravessiaConteudos.map(function(item, i){                           
                        return(
                            <ItemBox limit info={item} key={i} func={()=> props.carregarConteudo(item.idConteudo)} type="mp"/>                           
                            )
                    })
                    : <Loader cor={true}/>
                    }


            </div>
            {<div className="col-xs-12  col-md-3" style={{marginTop: 10}}>    
                <p>{props.dadosObra.CRM_TEXTO_QUESTAO}</p>                
            </div> }
        </div> 
    </Fragment>

    )
}

export default ListBox
