import React from 'react'
import './listaBox.css'
import './llivrestes.css'
const listBoxItemLivrete = (props) =>{
    let info = props.info
        if(!info)
            return null
          
            switch(props.type){
                default :
                return(
                <div className="clearfix">
                    <div className="col-md-12 col-xs-12">
                        <img alt="Imagem do livrete" className="img-responsive" src={ info.imagemMiniatura ? `${info.imagemOriginal}` : {}} onClick={()=>props.func(info.idConteudo)}/>
                    </div>
                    <div className="col-md-12 col-xs-12">
                        <h6 className="titulo-art text-bold text-main">{info.titulo || "---"}</h6>
                        <div dangerouslySetInnerHTML={{__html: info.resumo}}></div>
                        <button className="text-atual btnClickArtigo" onClick={props.func}>
                            Visualizar
                        </button>
                    </div>
                </div>
                )
   
    }   
}






export default listBoxItemLivrete