import React from 'react'
import RespostaAfiliado  from './responder'



function nivelis(mostrar, comentario){
    return(
    <div className={`respostas mg-40-top`}> 
        <div className="respostaFiliado">
            <p className="text-left">Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum</p>
        </div> 
        <div className="informacoesForum">
            <div className="inforFiliado">
                <img src="/images/usuario.png" alt=""/>
                <p className="italic text-left">Criado Por: Maria<br/>
                    15/08/2019</p>
            </div>
            <p className="bold">Avaliado por: Jose</p>
        </div>
        <div className="add-comentario" onClick={comentario}>
            {mostrar ? " " : <img src="/images/add-comentario.png" alt=""/>}
        </div>
        <RespostaAfiliado mostrar={mostrar} comentario={comentario}/>
    </div>
    )
}

class  Responder extends React.Component{
    state={
        abrirResposta: false
    }

    abrirCampoResposta = () =>{
        this.setState({abrirResposta:!this.state.abrirResposta})
    }
    render(){
        return nivelis(this.state.abrirResposta, this.abrirCampoResposta)
    }
}

export default Responder