import React from 'react'
import {BrowserRouter, Switch, Route} from 'react-router-dom'
import indexGeral from '../containers/indexGeral'
import indexObra from '../containers/indexObra'
import Insperacoes from '../containers/obra/Inspiracao'
import MPTravessia from '../containers/obra/MPTravessia'
import Login from '../containers/login/Login'
import Livretes from "../containers/obra/Livretes"
import Auth from '../hoc/Auth'
import Metagram from '../containers/metagram/metagramControler'
import Apoio from '../containers/orientacaoApoio'
import Forum from '../containers/forum'
import Ferramentas from '../containers/obra/Ferramentas'
import TopicoForum from '../containers/forum/topico'

const Routes =  ( ) =>(
    <BrowserRouter>
        <div className="routerWrapper">
            <Switch>
                {/*acesso para todos  */        
                <Route path ='/' exact component={Login} /> }      

                {/*consolidação da cultura*/}
                <Route path ='/obra' exact component={Auth(indexObra)} />

                {/* MP da travessia */}
                <Route path ='/mp-da-travessia' exact component={Auth(MPTravessia)} />

                {/* Inspirações */}
                <Route path ='/Inspiracoes' exact component={Auth(Insperacoes)} />

                <Route path='/livretes' exact component={Auth(Livretes)}/>
                
                <Route path='/ferramentas' exact component={Auth(Ferramentas)}/>

                {/* login */}
                <Route path ='/login' exact component={Login} /> 
                
                {/* metagram */}
                <Route path ='/metagram' exact component={Auth(Metagram)} />

                {/* metagram */}
                <Route path ='/forum' exact component={Auth(Forum)} />

                {/* orientacao-e-apoio' */}
                <Route path ='/orientacao-e-apoio' exact component={Auth(Apoio)} /> 

                {/* Topico Forum' */}
                <Route path ='/topico' exact component={Auth(TopicoForum)} /> 
            </Switch>
        </div>
    </BrowserRouter>
)

export default Routes