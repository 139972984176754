const modal = null
const Apoio = (state = {
    modal
}, action) => {
    switch (action.type) {
        case "APOIO_MODAL":
            return { ...state, modal: action.payload}
        case "APAGAR_MODAL":
            return {...state, modal: null}
        default:
            return state
    }
}
export default Apoio