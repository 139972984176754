import React from 'react'
import SvgSenha from './trocarSenha.svg'
import './icons.css'

const TrocarSenha = (props)=>{
    return(
        <img src={SvgSenha} alt="trocar senha" className="trocarSenha" onClick={props.f_func}/>
    )
}

export default TrocarSenha