import React from 'react'

const bodyMetagram = (props)=>{
    return(
        <div className="body-box" style={{backgroundColor: props.cor ? props.cor : "#00ae4c"}}>
            <div className="conteudo">
                <h4 className="text-center">{props.source}</h4>
            </div>
        </div>
    );
}

export default bodyMetagram