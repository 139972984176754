import React from 'react'

const Slider = () =>{
    return(
    <div className="col-sm-12 componente-pesquisa">
        <div className="row">
            <div id="banner">
                <div className="col-sm-12 banner-home">
                    <div className="filter" >
                        <h4 className="text-inverse">Clique na imagem para logar.</h4>
                    </div>
                </div>
            </div>
        </div>
    </div>
    )
}

export default Slider