import React from 'react'
import Avaliar from '../avaliar/avaliar'

const Content = (props)=>{
  console.log(props)
    return(
      <>
        <div className="conteudo-box-home">
          <h4 className="bold">{props.titulo}</h4>
          {(props.conteudo.podeAlterar && props.conteudo.categoria === "MP Travessia") ?          
            <Avaliar progresso={props.conteudo.percentualTravessia} idConteudo={props.conteudo.idConteudo}/>
          : 
          null}
          <hr />
          <img src={props.imagemOriginal} alt="" className="img-responsive mg-20-bottom"/>
          {props.conteudo.resumo && <>
          <h5>Descritivo</h5>
          <blockquote className="curtoQuotes" dangerouslySetInnerHTML={{__html: props.conteudo.resumo}}></blockquote>
          </>}
          <div id="conteudoMain" dangerouslySetInnerHTML={{__html: props.descricao}}></div>
        </div>
      </>      
    )
}

export default Content