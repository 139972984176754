import React, {Component} from 'react'
import Layout from '../components/layout'
import Slider from '../components/sliders/Slider'
import ContainerWithRow from '../components/ui/bootstrap/ContainerWithRow'
import {Link} from 'react-router-dom'
import {connect} from 'react-redux'
import Galeria from '../components/galeria/GaleriaControle'
import Anchor from 'react-anchor-link-smooth-scroll'


class IndexGeral extends Component{
    state = {
        showConteudo: false,
        showMobMenu: false
    }

    f_modal_conteudo = (valor) => {
        if(valor === false){
            this.props.limparConteudoPagina()
        }
        this.setState({
            showConteudo: valor
        })
    }
    f_carregarConteudo(id){
        this.f_modal_conteudo(true)
        this.props.pegarConteudoUnico(id);        
    }
    render(){

        return(
            <Layout>
                <div className="indexGeral">
                    <ContainerWithRow fluid={true}> 
                        <Link to="/login">
                            <Slider />
                        </Link>
                        <div className="links-home">
                            <ul>
                                <li>
                                    <Anchor href="#falaDevir">Fala do Devir</Anchor>
                                </li>
                                <li>
                                    <Anchor href="#galeria">Galeria</Anchor>
                                </li>
                            </ul>
                        </div>
                    </ContainerWithRow>



                </div>                
            </Layout>


        )
    }
}




export default connect(null)(IndexGeral)

