import React, {useState, useEffect} from 'react'
import Backdrop           from '../../components/ui/modal/backdrop'
import {connect}          from 'react-redux'
import Alerta             from './alerta'

const ModalAlerta =  (props)=>{

  const [show, setarShow] = useState(false)

  useEffect(()=>{
    const contarAlertas = props.alertas.length
    console.log(props.alertas)
    if(contarAlertas > 0)
    {
      setarShow(true)
    }
    else{
      setarShow(false)
    }
  },[props.alertas])
    
  return(
    <>
      <Backdrop classBd='backdrop-galeria' modal={show} f_fechar={()=>setarShow(!show)} esconderFechar={false}>
        <div className="container">
            <div className="modal-senha">
                <div className="col-sm-6">
                    <div className="alterar-senha">
                        <h4 className="text-main text-left bold">Sua obra precisa de ajuda.</h4>
                        <small>Responda as pesquisas abaixo</small>
                        {props.alertas ?
                          props.alertas.map((item, i)=>{
                            return <Alerta key={Math.random * i} alerta={item}/>
                          })
                        :
                        null }
                      </div>
                  </div>
              </div>
          </div>
        </Backdrop>
    </>

  )
}


const mapStateToProps = ( state )=>{
  return{
      alertas: state.alertas.alertasGerais
  }
}



export default connect(mapStateToProps, null)(ModalAlerta)