import React from 'react'


const listBoxItem = (props) =>{
    let info = props.info
        if(!info)
            return null
           
        switch(props.type){
            case "pequeno":
                return(
                    <div className="listaBox  artigos-home clearfix">
                    <div className="col-md-4 col-xs-3">
                        <div className="bg-artigos" style={ info.imagemMiniatura ? {backgroundImage: `url(${info.imagemOriginal})`} : {}} onClick={()=>props.func(info.idConteudo)}/>
                        
                        </div>
                        <div className="col-md-8 col-xs-9">
                        <h6 className="titulo-art text-bold text-main">{info.titulo || "---"}</h6>
                        <h6 className="text-left"><small>{info.categoria}</small></h6>
                        <div dangerouslySetInnerHTML={{__html: info.resumo}}>
                        </div>
                        <button className="text-atual btnClickArtigo" onClick={()=>props.func(info.idConteudo)}>
                            Veja
                        </button>
                    </div>
                </div> 
                )
            case "mp":
                return(
                    <div className="listaBox artigos-home tracado clearfix">
                        <div className="col-md-12 col-xs-12">
                            <h5 className="titulo-art text-bold text-main tracado-titulo" onClick={props.func}>{info.titulo || "---"}</h5>
                            <div className="progress" onClick={props.func}>
                                <div className="progress-bar" role="progressbar" aria-valuenow={70} aria-valuemin={0} aria-valuemax={100} style={{width: `${info.percentualTravessia * 10}%`}}>
                                <span className="pes"></span>
                                </div>
                            </div>
                        </div>
                    </div>
                )
            default :
            return(
            <div className="listaBox artigos-home clearfix">
                <div className="col-md-3 col-xs-12">
                    <div className="bg-artigos" style={ info.imagemMiniatura ? {backgroundImage: `url(${info.imagemOriginal})`} : {}} onClick={()=>props.func(info.idConteudo)}/>
                    </div>
                    <div className="col-md-9 col-xs-12">
                    <h6 className="titulo-art text-bold text-main">{info.titulo || "---"}</h6>
                    <h6 className="text-left"><small>{info.categoria}</small></h6>
                    <div dangerouslySetInnerHTML={{__html: info.resumo}}>
                    </div>
                    <button className="text-atual btnClickArtigo" onClick={props.func}>
                        {info.secao === "Audio Devir" ? "Ouça" : "Veja"}
                    </button>
                </div>
            </div>
        )
   
    }   
}






export default listBoxItem