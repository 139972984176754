import React, { Component, Fragment } from 'react'

class Responder extends Component{

    render(props){
        return(
            <Fragment>
                <div className="container">
                    <div className="row">
                        <div className="criarTopico">
                            <div className="col-md-1 col-sm-2 col-xs-2">
                                <div className="radar"></div>
                                <button className="btn-mais"></button>
                            </div>
                            <div className="col-md-11 col-sm-10 col-xs-10">
                                <div className="form-group">
                                    <div className="pesquisar">
                                        <input type="text" placeholder="Procurar um tópico" className="form-control input-lg"/>
                                        <i className="material-icons">search</i>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Fragment>
        )
    }
}
export default Responder