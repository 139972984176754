import React        from 'react'
import PropTypes    from 'prop-types'
import Content      from './content'
import ContentDown  from './contentDown'
import Backdrop     from '../ui/modal/backdrop'
import Loader       from '../ui/loader/index'
import './conteudo.css'
import Comentarios from '../comentarios/comentarios'
import Responder from '../comentarios/responder'

const comentarios = ()=>{
  return(
    <>
    <div className="conteudo-box-home mg-20-top mg-20-bottom">
      <div className="listaDeComentarios">
          <Responder />
          <Comentarios />
      </div>
    </div>
    </>
  )
}
const Conteudo = (props) =>{

  let conteudo = props.conteudo[0]
  
  let download = false 
  if(props.conteudo){
    if(props.conteudo.length > 1){
      download = true
    }
  }

  const podeEditarMP = true
  if(conteudo){
    if(conteudo.possui_descricao_restrita){
      conteudo.descricao += conteudo.descricao_restrita 
    }
  }


  

  return(
    <Backdrop classBd={`backdrop backdrop-modal ${props.show ? "aberto" : ""}`}  f_fechar={props.f_fechar_conteudo}>
      <div className="modalConteudo">
        <div className="col-md-12 col-sm-12 col-xs-12" style={{position: "relative"}}>
            {conteudo ? 
            <div>
              <Content titulo={conteudo.titulo} descricao={conteudo.descricao} imagemOriginal={conteudo.imagemOriginal} podeEditarMP={podeEditarMP} conteudo={conteudo}/> 
              {
                download ? <ContentDown materiais={props.conteudo}/> : null
              }
              {comentarios()}
            </div>            
            : 
            <Loader />}           
        </div>
      </div>
    </Backdrop>
    )  
}

Conteudo.propTypes = {
  show: PropTypes.bool.isRequired,
  f_fechar_conteudo: PropTypes.func.isRequired,
}

Conteudo.defaultProps = {
  show: false
}

export default Conteudo