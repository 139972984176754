import React, {useState, useEffect} from 'react';
import {_API, _EMPRESA} from '../../config/config'
import axios from 'axios'
import {connect} from 'react-redux'
import { listarConteudos } from '../../store/actions/action.obra'



const Avaliar = ( props ) => {
    const [progresso, setarProgresso] = useState(props.progresso || 0);
    const estrelas = 10
    const id = props.idConteudo
    useEffect(() => {
        axios.post(`${_API}/gravarTracado`, {
            cad_empresa : _EMPRESA,
            cms_id_usuario: localStorage.getItem('_IDUSER'),
            percentual: progresso,
            cms_id_conteudo: id
          })
          .then((res)=>{
            const result = res.data[0].RESULTADO.split("|")[0]
            console.log(result)
            props.pegarConteudos()
          }) 
      }, [progresso]);

    let arrayEstrelas = [];

    for(let i = 0; i <= estrelas; i++){
        if(i === 0){

        }else{
          arrayEstrelas.push( <div key={i} className={`avaliar ${i <= progresso ? "avaliar-ativo" : ""}`} onClick={()=> setarProgresso(i)}><span></span></div>)
        }
          
    }

    return(
        <div className="avliarMP">
            Evolução desse feixe
          <div className="avaliacao">            
            {arrayEstrelas}
          </div>
        </div>
    )
}

const mapActionsToDispatch = (dispatch) => {
    return {
        pegarConteudos: ()=> dispatch(listarConteudos()),
    }
}


export default connect(null, mapActionsToDispatch)(Avaliar)