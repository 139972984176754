const Noticias = (state = {
    noticiasGerais: [],
    conteudos: [],
    livretes:[],
    aniversariantes: [],
    dados: [],
    graficoLume: {},
    leituraSliderConteudo: {},
    conteudoPagina: {},
    login: "",
    imagemTravessia: {},
    token: "",
    videos: []
}, action) => {
    switch (action.type) {
        case "VALIDAR_LOGIN":
            return { ...state, login: action.payload }
        case "GERAR_TOKEN":
            return { ...state, token: action.payload }
        case "LISTAR_CONTEUDOS":
            return { ...state, conteudos: action.payload }
        case "LISTAR_ANIVERSARIANTES":
            return { ...state, aniversariantes: action.payload }
        case "LISTAR_DADOS_OBRA":
            return { ...state, dados: action.payload }
        case "BUSCAR_GRAFICO_LUME":
            return { ...state, graficoLume: action.payload }
        case "LEITURA_SLIDER_CONTEUDO":
            return { ...state, leituraSliderConteudo: action.payload }
        case "CONTEUDO":
            return { ...state, conteudoPagina: action.payload }
        case "NOTICIAS_GERAIS":
            return { ...state, noticiasGerais: action.payload }
        case "LIMPAR_CONTEUDO":
            return { ...state, conteudoPagina: action.payload }
        case 'SLIDER_IMAGEM':
            return { ...state, imagemTravessia: action.payload[0] }
        case "LISTA_LIVRETES":
            return {...state, livretes: action.payload}
        case "LISTAR_FERRAMENTAS":
            return {...state, ferramentas: action.payload}
        case "LISTAR_VIDEOS":
            return {...state, videos: action.payload}
        default:
            return state
    }
}



export default Noticias