import React,{useEffect, useState} from 'react'
import PropsTypes from 'prop-types'

const Alert = (props) =>{
   const [mostrar, setarMostrar] = useState(true)
   return(
        <>
        {mostrar ?
            <div className={`alert alert-${props.type}`} role="alert">
                <button style={{fontSize: '21px' }} type="button" className="close" onClick={()=> setarMostrar(false)} data-dismiss="alert" aria-label="Close"><span aria-hidden="true">&times;</span></button>
                {props.msg}
            </div>  
             :
            null
            } 
        </>  
    )
}

Alert.propTypes = {
    type: PropsTypes.string
}

Alert.defaultProps = {
    type: "danger"
}
export default Alert