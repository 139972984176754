import axios from 'axios'
import { _API, _EMPRESA, _SESSAO_CAT_GERAL_, _SESSAO_CAT_OBRA_ } from '../../config/config'

export async function validarLogin(empresa = null, cms_login = null, cms_senha = null) {

    let request = await axios.post(`${_API}/validarLogin`, {
        empresa: empresa,
        cms_login: cms_login,
        cms_senha: cms_senha
    })
        .then(response => {

            return response.data
        })
    return {
        type: "VALIDAR_LOGIN",
        payload: request
    }
}

export async function gerarToken(empresa = null, url_token = null) {

    let request = await axios.post(`${_API}/gerarToken`, {
        empresa: empresa,
        cms_id_usuario: localStorage.getItem("_IDUSER"),
        url_token: url_token
    })
        .then(response => {

            return response.data
        })
    return {
        type: "GERAR_TOKEN",
        payload: request
    }
}

export async function listarConteudos(cms_id_conteudo = null, cms_id_categoria = null, cms_id_secao = null, inicial = 0, final = 0) {

    let request = await axios.post(`${_API}/listarConteudos`, {
        cad_empresa: _EMPRESA,
        cms_id_usuario: localStorage.getItem("_IDUSER"),
        cms_id_conteudo: cms_id_conteudo,
        cms_id_categoria: _SESSAO_CAT_OBRA_,
        cms_id_secao: cms_id_secao,
        inicial: inicial,
        final: final
    })
        .then(response => {

            return response.data
        })
    return {
        type: "LISTAR_CONTEUDOS",
        payload: request
    }
}


export async function listarGeral(cms_id_conteudo = null, cms_id_categoria = null, cms_id_secao = null, inicial = 0, final = 0) {

    let request = await axios.post(`${_API}/listarConteudos`, {
        cad_empresa: _EMPRESA,
        cms_id_usuario: localStorage.getItem("_IDUSER"),
        cms_id_conteudo: cms_id_conteudo,
        cms_id_categoria: _SESSAO_CAT_GERAL_,
        cms_id_secao: cms_id_secao,
        inicial: inicial,
        final: final
    })
        .then(response => {

            return response.data
        })
    return {
        type: "NOTICIAS_GERAIS",
        payload: request
    }
}

export async function listarLivretesMetanoia(cms_id_conteudo = null, cms_id_categoria = null, cms_id_secao = null, inicial = 0, final = 0) {
    let request = await axios.post(`${_API}/listarConteudos`, {
        cad_empresa: _EMPRESA,
        cms_id_usuario: localStorage.getItem("_IDUSER"),
        cms_id_conteudo: cms_id_conteudo,
        cms_id_categoria: "0012",
        cms_id_secao: cms_id_secao,
        inicial: inicial,
        final: final
    })
        .then(response => {
            return response.data
        })
    return {
        type: "LISTA_LIVRETES",
        payload: request
    }
}


export async function listarCaixaDeFerramentas(cms_id_conteudo = null, cms_id_categoria = null, cms_id_secao = null, inicial = 0, final = 0) {

    let request = await axios.post(`${_API}/listarConteudos`, {
        cad_empresa: _EMPRESA,
        cms_id_usuario: localStorage.getItem("_IDUSER"),
        cms_id_conteudo: cms_id_conteudo,
        cms_id_categoria: "0014",
        cms_id_secao: cms_id_secao,
        inicial: inicial,
        final: final
    })
        .then(response => {

            return response.data
        })
    return {
        type: "LISTAR_FERRAMENTAS",
        payload: request
    }
}

export async function listarAniversariantes() {

    let request = await axios.post(`${_API}/listarAniversariantes`, {
        cad_empresa: _EMPRESA,
        cms_id_usuario: localStorage.getItem("_IDUSER"),
    })
        .then(response => {

            return response.data
        })

    return {
        type: "LISTAR_ANIVERSARIANTES",
        payload: request
    }
}

export async function listarDadosObra(texto = null) {
    let dados = {
        type: "LISTAR_DADOS_OBRA",
        payload: ""
    }
    let request = await axios.post(`${_API}/listarDadosObra`, {
        cad_empresa: _EMPRESA,
        cms_id_usuario: localStorage.getItem("_IDUSER"),
        texto: texto
    })
    .then(response => {
        return response.data
    })
    .catch((err)=>{
        dados.type = "TEM_ERRO"
        return String(err)
    })
    return {
       ...dados,
       payload: request
    }
}

export async function buscarGraficoLume() {
    let request = await axios.post(`${_API}/buscarGraficoLume`, {
        cad_empresa: _EMPRESA,
        cms_id_usuario: localStorage.getItem("_IDUSER")
    })
        .then(response => {

            return response.data
        })
    return {
        type: "BUSCAR_GRAFICO_LUME",
        payload: request
    }
}

export async function leituraSliderConteudo(cms_id_conteudo = null) {

    let request = await axios.post(`${_API}/leituraSliderConteudo`, {
        cad_empresa: _EMPRESA,
        cms_id_usuario: localStorage.getItem("_IDUSER"),
        cms_id_conteudo: "00000009"
    })
        .then(response => {

            return response.data
        })
    return {
        type: "LEITURA_SLIDER_CONTEUDO",
        payload: request
    }
}


export async function imagemMPTravessia(cms_id_conteudo = null) {

    let request = await axios.post(`${_API}/leituraSliderConteudo`, {
        cad_empresa: _EMPRESA,
        cms_id_usuario: localStorage.getItem("_IDUSER"),
        cms_id_conteudo: "00000027"
    })
        .then(response => {

            return response.data
        })
    return {
        type: "SLIDER_IMAGEM",
        payload: request
    }
}


export async function leituraConteudo(cms_id_conteudo = null) {

    let request = await axios.post(`${_API}/leituraSliderConteudo`, {
        cad_empresa: _EMPRESA,
        cms_id_usuario: localStorage.getItem("_IDUSER"),
        cms_id_conteudo: cms_id_conteudo
    })
        .then(response => {

            return response.data
        })
    return {
        type: "CONTEUDO",
        payload: request
    }
}


export async function limparConteudo() {
    return {
        type: "LIMPAR_CONTEUDO",
        payload: {}
    }
}

export async function leituraRealizacao(cms_id_conteudo = null) {

    let request = await axios.post(`${_API}/leituraSliderConteudo`, {
        cad_empresa: _EMPRESA,
        cms_id_usuario: localStorage.getItem("_IDUSER"),
        cms_id_conteudo: "00000367"
    })
        .then(response => {
            console.log(response.data)
            return response.data
        })
    return {
        type: "LEITURA_REALIZACAO_CONTEUDO",
        payload: request
    }
}


export async function listarVideos(cms_id_conteudo = null, cms_id_categoria = null, cms_id_secao = null, inicial = 0, final = 0) {

    let request = await axios.post(`${_API}/listarConteudos`, {
        cad_empresa: _EMPRESA,
        cms_id_usuario: localStorage.getItem("_IDUSER"),
        cms_id_conteudo: cms_id_conteudo,
        cms_id_categoria: "0010",
        cms_id_secao: cms_id_secao,
        inicial: inicial,
        final: final
    })
        .then(response => {

            return response.data
        })
    return {
        type: "LISTAR_VIDEOS",
        payload: request
    }
}


