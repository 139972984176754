import React from 'react'
import PropsTypes from 'prop-types'

const itemGaleria = (props) =>{
    return(
        <div className="col-lg-2 col-md-4 col-sm-4 col-xs-6 galeria-item">
            <div className="foto-item" 
            style={{backgroundImage: `url(${props.srcImage})`}}
            onClick={props.f_modal}
            >
                <div className="fotosHover">
                    <p className="link-fotos">Veja a foto</p>
                </div>
            </div>
        </div>       
    )
}

itemGaleria.propTypes = {
    srcImage: PropsTypes.string.isRequired,
    f_modal: PropsTypes.func.isRequired
}

export default itemGaleria