import React from 'react'
import PropsTypes from 'prop-types'
import './backdrop.css'

const Backdrop = (props)=>{
    let cs = `backdrop backdrop-modal backdrop-galeria ${props.classBd} ${props.modal ? "aberto" : "" }`
    return(
        <div className={cs} >
            {props.esconderFechar ?
                null
            :
                <div className="fechar" onClick={props.f_fechar}>
                    <span>x</span>
                </div>
            }

            {props.children}
        </div>
    )
}

Backdrop.propTypes = {
    modal:       PropsTypes.bool.isRequired,
    f_fechar:   PropsTypes.func
}

Backdrop.defaultProps = {
    modal: false
  }
  


export default Backdrop