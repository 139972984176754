import React from 'react'
import RespostaForum from './parts/respostaForum'
import './forum.css'


const ItemForum = (props) =>{
    return(

        <div className="container">
            <div className="row">
                <div className="col-sm-12 ">
                    <div className="lista-respostas">
                        <RespostaForum mostrar={props.mostrar} comentario={props.comentario} />
                    </div>
                </div>
            </div>
        </div>

    )
}

export default ItemForum